import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  StreetViewPanorama,
  GoogleMapProps,
} from "@react-google-maps/api";

//Styled components
import {
  Container,
  TableContainer,
  MapContainer,
  MapBar,
  MapBarHeader,
  NewMapButton,
  MapsContainer,
  MapDivRight,
  AlertDiv,
  AlertDivHeader,
} from "./styles";

import {
  CustomInfoWindow,
  InfoWindowTextContainer,
  HelpButton,
  MarkersLegend,
  LegendItem,
} from "../OperatorMap/styles";

//Icons
import { MdAddCircleOutline } from "react-icons/md";
import { BiHelpCircle } from "react-icons/bi";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// import carIcon from "@assets/markers/car34.png";

// import { markerIcons } from "@resources/markerIcons";

//MUI
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Custom Components
// import AdminMapCard from "@components/AdminMapCard";
// import CustomLoadingPage from "@components/CustomLoadingPage";
// import CustomTable from "@components/CustomTable";
// import ModalDeleteMap from "@components/ModalDeleteMap";
// import ModalDetailsAdmin from "@components/ModalDetailsAdmin";
// import ModalFormMap from "@components/ModalFormMap";
// import ModalReportAdmin from "@components/ModalReportAdmin";
// import ProfileButton from "@components/ProfileButton";
// import PointIcon from "@components/PointIcon";

//APIS
import * as Geocode from "react-geocode";

//JSON
// import tableColumnsMap from "@resources/tableColumnsMap.json";

//Utils
import { alertErrorStyle } from "@resources/alertErrorStyle";
import { toast } from "react-toastify";
// import ModalHeader from "@components/Modal/";
import { markersHelper } from "@helpers/markers";
// import EspeciesMapFilter from "./components/EspeciesMapFilter";
import { arrayHelper } from "@helpers/array";
// import useOperatorLocation from "@hooks/useOperatorLocation";
// import OperatorLocation from "./components/OperatorLocation";
import useUser from "@hooks/useUser";
import useMaps from "@hooks/useMaps";
import PageLoading from "@components/PageLoading/PageLoading";
import useMapClients from "@hooks/useMapClients";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import { Map } from "@models/Map";
import { Client } from "@models/Client";

const alertOptions = [
  {
    title: "endereços não encontrados",
    value: "endNotFound",
  },
  {
    title: "não encontrados pelos operadores",
    value: "endNotFoundOperator",
  },
  {
    title: "localidades diversas",
    value: "differentLocation",
  },
];

type SelectedMap = {
  name: string;
  lastMapName?: string;
  cities: Map["cities"];
  clients: Client[];
  clientsBackup: Client[];
  calculate: boolean;
};

type MapState = {
  center: { lat: number; lng: number };
  zoom: number;
};

const MapPage = () => {
  Geocode.setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("pt-BR");

  const dateNow = new Date();

  const { isAdmin, isManager, user } = useUser();

  const { mapClients } = useMapClients();
  const { getMaps } = useMaps();

  //   const { operatorsLocation } = useOperatorLocation();

  const [loadingData, setLoadingData] = useState(true);

  //Clientes and Table
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [clientes, setClientes] = useState([]);
  const [clientesBackup, setClientesBackup] = useState([]);
  const [clientesBackupTable, setClientesBackupTable] = useState([]);

  //Client manager
  const [viewDetails, setViewDetails] = useState(false);
  const [currentClient, setCurrentClient] = useState<Client>();
  const [dataType, setDataType] = useState("clientsMap");

  //Modal
  const [modalFormMap, setModalFormMap] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalFullData, setModalFullData] = useState(false);
  const [modalReport, setModalReport] = useState(false);

  //Map manager
  const [selectedMap, setSelectedMap] = useState<SelectedMap>();
  const [newMap, setNewMap] = useState(false);
  const [modalDeleteMap, setModalDeleteMap] = useState(false);
  const [mapToDelete, setMapToDelete] = useState("");

  //Map
  // const [mapRef, setMapRef] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [lastZoom, setLastZoom] = useState("");
  const [lastCenter, setLastCenter] = useState({ lat: 0, lng: 0 });
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(5);
  const [streetView, setStreetView] = useState(false);
  const [expandedAlert, setExpandedAlert] = useState(false);
  const [alertsNumber, setAlertsNumber] = useState("");
  const [showInfoWindow, setShowInfoWindow] = useState({ status: false, client: null });
  const [markers, setMarkers] = useState(false);
  const [mapsData, setMapsData] = useState([]);
  const [optionsCities, setOptionsCities] = useState([]);
  const [legend, setLegend] = useState(false);
  const [clearEspeciesFilter, setClearEspeciesFilter] = useState(false);

  const [mapState, setMapState] = useState<MapState>({
    center: { lat: -8.06036, lng: -34.921713 },
    zoom: 10,
  });
  const mapRef = useRef<GoogleMap>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  console.log("mapClients: ", mapClients);

  useEffect(() => {
    (async () => {
      if (mapClients) {
        const maps = await getMaps();

        const validCities = [];
        const arrayMaps = [];

        const clientsMap = mapClients;
        const allCities = [];

        //   for (const cliente of mapClients) {
        //     if (cliente.selected != "done" && cliente.endNotFound != true) {
        //       clientsMap.push(cliente);
        //     }
        //   }

        //   if (isAdmin) {
        //     for (const i in mapClients) {
        //       const mapDoc = maps.find((x) => x.docId === mapClients[i].mapId);
        //       // const result = mapDoc.cities.find(x => x === mapClients[i].cidadeMap);

        //       if (!mapDoc) {
        //         const inValid = validCities.find((x) => x.value === mapClients[i].cidadeMap);
        //         if (!inValid) {
        //           validCities.push({
        //             value: mapClients[i].cidadeMap,
        //             label: mapClients[i].cidadeMap,
        //           });
        //         }
        //       }
        //     }

        //     getAlerts();

        //     for (const map of maps) {
        //       const clientes = clientsMap.filter((x) => x.mapId === map.docId);

        //       arrayMaps.push({
        //         ...map,
        //         clientes: clientes,
        //         clientesBackup: clientes,
        //         calculate: true,
        //       });

        //       allCities = allCities.concat(map.cities);
        //     }

        //     arrayMaps.unshift({
        //       mapName: "Geral",
        //       cities: allCities,
        //       clientes: clientsMap,
        //       clientesBackup: clientsMap,
        //       calculate: true,
        //     });

        if (!selectedMap) {
          setSelectedMap({
            name: "Geral",
            cities: allCities,
            clients: clientsMap,
            clientsBackup: clientsMap,
            calculate: false,
          });
        }

        //     setMapsData(arrayMaps);
        //     setOptionsCities(validCities);
        //     handleSelectedMap(arrayMaps[0]);
        //   }

        //   if (isManager && user?.mapsArray) {
        //     for (const map of maps) {
        //       const managerMap = user?.mapsArray?.find((x) => x === map.docId);

        //       if (managerMap) {
        //         const clientes = clientsMap.filter((x) => x.mapId === map.docId);

        //         arrayMaps.push({
        //           ...map,
        //           clientes: clientes,
        //           clientesBackup: clientes,
        //         });
        //       }
        //     }

        //     setMapsData(arrayMaps);
        //     handleSelectedMap(arrayMaps[0]);
        //   }

        //   if (currentClient) {
        //     const updatedClient = clientsMap.find((x) => x.cpf == currentClient.cpf);

        //     if (updatedClient) {
        //       setCurrentClient(updatedClient);
        //     }
        //   }

        setClientesBackup(clientsMap);
        switch (dataType) {
          case "clientsMap":
            console.log("AQUI");
            setClientes(clientsMap);
            setClientesBackupTable(clientsMap);
            break;
          default:
            break;
          // case alertOptions[0].value:
          //   getEndNotFound();
          //   break;
          // case alertOptions[1].value:
          //   getEndNotFound();
          //   break;
          // case alertOptions[2].value:
          //   getDifferentLocation();
          //   break;
        }

        setMarkers(true);
        setLoadingData(false);
      }
    })();
  }, [mapClients, user]);

  useEffect(() => {
    if (dataType == "clientsMap" && selectedMap) {
      setClientes(selectedMap.clients);
    }
    if (selectedMap && selectedMap.clients?.length > 0) {
      if (selectedMap.calculate && selectedMap.lastMapName != selectedMap.name) {
        calculateMapCenter(selectedMap.clients, null, null);
      }
    }
  }, [selectedMap]);

  const calculateMapCenter = (clientes, defaultCenter, defaultZoom) => {
    const arrayLat = [];
    const arrayLng = [];
    let valueBase = 0;

    if (defaultCenter && defaultZoom) {
      return changeMapCenter(defaultCenter, defaultZoom);
    }

    if (selectedMap.name != "Geral") {
      valueBase = 10.5215082;
    } else {
      valueBase = 8.39715082;
    }

    for (const cliente of clientes) {
      if (
        cliente.cidadeMap != "SP - TREMEMBÉ" &&
        cliente.cpf != "0" &&
        cliente.cpf != "11" &&
        cliente.cpf != "111"
      ) {
        arrayLat.push(cliente.lat);
        arrayLng.push(cliente.lng);
      }
    }

    const centerLat = (getMaxOfArray(arrayLat) + getMinOfArray(arrayLat)) / 2;
    const centerLng = (getMaxOfArray(arrayLng) + getMinOfArray(arrayLng)) / 2;

    const dif = getMaxOfArray(arrayLat) - getMinOfArray(arrayLat);

    if (clientes.length > 1) {
      changeMapCenter({ lat: centerLat, lng: centerLng }, (valueBase / dif) * dif);
    } else {
      if (clientes.length == 0) {
        const defaultCoords = { lat: -8.06036, lng: -34.921713 };

        changeMapCenter(defaultCoords, valueBase);
        return toast("Sem clientes no mapa", alertErrorStyle);
      } else {
        changeMapCenter({ lat: clientes[0].lat, lng: clientes[0].lng }, valueBase);
      }
    }
  };

  const onMapIdle = useCallback((map: GoogleMapProps) => {
    if (map) {
      const newCenter = map.center;
      const newZoom = map.zoom;

      setMapState({
        zoom: newZoom,
        center: {
          lat: typeof newCenter.lat === "number" ? newCenter.lat : newCenter.lat(),
          lng: typeof newCenter.lng === "number" ? newCenter.lng : newCenter.lng(),
        },
      });
    }
  }, []);

  // const handleSelectedMap = (map) => {
  //   if (selectedMap) {
  //     setSelectedMap((prev) => {
  //       if (prev.mapName) {
  //         return {
  //           ...map,
  //           calculate: true,
  //           lastMapName: prev && prev.mapName ? prev.mapName : map.mapName,
  //         };
  //       } else {
  //         return {
  //           ...map,
  //           calculate: true,
  //         };
  //       }
  //     });
  //   } else {
  //     setSelectedMap({
  //       ...map,
  //       calculate: true,
  //     });
  //   }
  // };

  const changeMapCenter = (mapCenter, zoom) => {
    setCenterMap(mapCenter);
    setMapZoom(zoom);
  };

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }

  function getMinOfArray(numArray) {
    return Math.min.apply(null, numArray);
  }

  // const getAlerts = async () => {
  //   const count = 0;
  //   const arrayAlerts = [];

  //   const arrayEndNotFound = [];
  //   const arrayDifferentLocation = [];

  //   for (const cliente of mapClients) {
  //     if (
  //       cliente.selected != "done" &&
  //       (cliente[alertOptions[0].value] == true || cliente[alertOptions[1].value] == true)
  //     ) {
  //       arrayEndNotFound.push(cliente);
  //     } else {
  //       if (cliente.selected != "done" && cliente[alertOptions[2].value] == true) {
  //         arrayDifferentLocation.push(cliente);
  //       }
  //     }
  //   }

  //   if (arrayEndNotFound.length > 0) {
  //     arrayAlerts.push({
  //       qtd: arrayEndNotFound.length,
  //       title: alertOptions[0].title,
  //       dataType: alertOptions[0].value,
  //     });
  //   }

  //   if (arrayDifferentLocation.length > 0) {
  //     arrayAlerts.push({
  //       qtd: arrayDifferentLocation.length,
  //       title: alertOptions[2].title,
  //       dataType: alertOptions[2].value,
  //     });
  //   }

  //   setAlerts(arrayAlerts);
  //   setAlertsNumber(arrayEndNotFound.length + arrayDifferentLocation.length);
  // };

  //   const createMap = async (mapData) => {
  //     const batchArray = [];
  //     batchArray.push(writeBatch(db));
  //     let operationCounter = 0;
  //     let batchIndex = 0;

  //     const arrayCities = [];
  //     const arrayClientsInCities = [];

  //     const dateNow = new Date();

  //     if (mapData === null || !mapData.mapName || mapData.cities.length === 0) {
  //       return toast("Preencha o nome e as cidades antes de salvar", alertErrorStyle);
  //     }

  //     const dataToPost = {
  //       mapName: mapData.mapName,
  //       cities: mapData.cities,
  //       time: dateNow.getTime(),
  //     };

  //     setModalLoading(true);

  //     const docId = await addDoc(collection(db, "maps"), dataToPost)
  //       .then(async (doc) => {
  //         let stringCidades = "";

  //         for (const item of mapData.cities) {
  //           if (stringCidades == "") {
  //             stringCidades = item.label;
  //           } else {
  //             stringCidades = stringCidades + " | " + item.label;
  //           }
  //         }
  //         const logMessage = `${mapData.mapName} foi criado com as cidades: [ ${stringCidades} ]`;

  //         await addMapLogs(doc.id, logMessage);

  //         toast(logMessage, alertSuccessStyle);
  //         return doc.id;
  //       })
  //       .catch(async (error) => {
  //         setModalLoading(false);
  //         return toast(`Erro ao criar mapa: ${error.code}`, alertErrorStyle);
  //       });

  //     for (const city of mapData.cities) {
  //       const result = arrayCities.find((x) => x.value === city.value);

  //       if (!result) {
  //         arrayCities.push(city.value);
  //       }
  //     }

  //     for (const client of mapClients) {
  //       if (arrayCities.includes(client.cidadeMap) && (!client.mapId || client.mapId != docId)) {
  //         arrayClientsInCities.push(client.cpf);
  //         const docRef = doc(db, "clientes", client.cpf);

  //         batchArray[batchIndex].update(docRef, {
  //           mapId: docId,
  //         });
  //         operationCounter++;

  //         if (operationCounter === 499) {
  //           batchArray.push(writeBatch(db));
  //           batchIndex++;
  //           operationCounter = 0;
  //         }
  //       }
  //     }

  //     if (arrayClientsInCities.length != 0 && batchArray.length != 0) {
  //       for (const batch of batchArray) {
  //         batch.commit().catch((error) => {
  //           toast(`Erro ao definir mapa dos clientes: ${error.code}`, alertErrorStyle);
  //         });
  //       }
  //     }

  //     setModalLoading(false);
  //     setNewMap(false);
  //     setModalFormMap(false);
  //   };

  //   const updateMap = async (mapData) => {
  //     if (mapData === null || !mapData.mapName || mapData.cities.length === 0) {
  //       return toast("Preencha o nome e as cidades antes de salvar", alertErrorStyle);
  //     }

  //     setModalLoading(true);

  //     const batchArray = [];
  //     batchArray.push(writeBatch(db));
  //     let operationCounter = 0;
  //     let batchIndex = 0;

  //     const arrayCities = [];
  //     const arrayClientsInCities = [];

  //     const docRef = doc(db, "maps", mapData.docId);

  //     await updateDoc(docRef, {
  //       mapName: mapData.mapName,
  //       cities: mapData.cities,
  //     })
  //       .then(async () => {
  //         let stringCidades = "";

  //         for (const item of mapData.cities) {
  //           if (stringCidades == "") {
  //             stringCidades = item.label;
  //           } else {
  //             stringCidades = stringCidades + " | " + item.label;
  //           }
  //         }
  //         const logMessage = `${mapData.mapName} foi atualizado e agora contêm as cidades: [ ${stringCidades} ]`;

  //         await addMapLogs(mapData.docId, logMessage);

  //         toast(logMessage, alertSuccessStyle);
  //       })
  //       .catch(async (error) => {
  //         const logMessage = `Erro ao atualizar o mapa ${mapData.mapName}: ${error.code}`;

  //         await addMapLogs(mapData.docId, logMessage);

  //         setModalLoading(false);
  //         return toast(logMessage, alertErrorStyle);
  //       });

  //     for (const city of mapData.cities) {
  //       const result = arrayCities.find((x) => x.value === city.value);

  //       if (!result) {
  //         arrayCities.push(city.value);
  //       }
  //     }

  //     for (const client of mapClients) {
  //       if (
  //         arrayCities.includes(client.cidadeMap) &&
  //         (!client.mapId || client.mapId != mapData.docId)
  //       ) {
  //         arrayClientsInCities.push(client.cpf);
  //         const docRef = doc(db, "clientes", client.cpf);

  //         batchArray[batchIndex].update(docRef, {
  //           mapId: mapData.docId,
  //         });
  //         operationCounter++;

  //         if (operationCounter === 499) {
  //           batchArray.push(writeBatch(db));
  //           batchIndex++;
  //           operationCounter = 0;
  //         }
  //       }
  //     }

  //     if (arrayClientsInCities.length != 0 && batchArray.length != 0) {
  //       for (const batch of batchArray) {
  //         batch.commit().catch((error) => {
  //           toast(`Erro ao definir mapa dos clientes: ${error.code}`, alertErrorStyle);
  //         });
  //       }
  //     }

  //     setModalLoading(false);
  //     setNewMap(false);
  //     setModalFormMap(false);
  //   };

  //   const deleteMap = async () => {
  //     setModalLoading(true);

  //     const docRef = doc(db, "maps", mapToDelete.docId);

  //     await deleteDoc(docRef)
  //       .then(async () => {
  //         const logMessage = `O mapa ${mapToDelete.mapName} foi excluído com sucesso.`;

  //         await addMapLogs(mapToDelete.docId, logMessage);
  //         toast(logMessage, alertSuccessStyle);
  //       })
  //       .catch(async (error) => {
  //         const logMessage = `Erro ao excluir o mapa ${mapToDelete.mapName}: ${error.code}`;

  //         await addMapLogs(mapToDelete.docId, logMessage);

  //         setModalLoading(false);
  //         toast(logMessage, alertErrorStyle);
  //       });

  //     setModalLoading(false);
  //     setMapToDelete("");
  //     setModalDeleteMap(false);
  //   };

  //   const getEndNotFound = async () => {
  //     const array = [];

  //     for (const cliente of mapClients) {
  //       if (
  //         (cliente.selected != "done" && cliente[alertOptions[0].value] == true) ||
  //         cliente[alertOptions[1].value] == true
  //       ) {
  //         array.push(cliente);
  //       }
  //     }

  //     setClientes(array);
  //     handleSelectedMap(mapsData[0]);
  //     setClientesBackupTable(array);

  //     setCurrentClient("");
  //     setShowInfoWindow({ status: false, cliente: null });
  //   };

  //   const getDifferentLocation = async () => {
  //     const array = [];

  //     for (const cliente of mapClients) {
  //       if (cliente.selected != "done" && cliente[alertOptions[2].value] == true) {
  //         array.push(cliente);
  //       }
  //     }

  //     setClientes(array);
  //     handleSelectedMap(mapsData[0]);
  //     setClientesBackupTable(array);
  //     setCurrentClient("");
  //     setShowInfoWindow({ status: false, cliente: null });
  //   };

  // const handleChangeData = async (event, newDataType) => {
  //   setDataType(newDataType);
  //   switch (newDataType) {
  //     case alertOptions[0].value:
  //       await getEndNotFound();
  //       break;
  //     case alertOptions[1].value:
  //       await getEndNotFound();
  //       break;
  //     case alertOptions[2].value:
  //       await getDifferentLocation();
  //       break;
  //   }
  // };

  if (loadingData || clientes.length === 0 || !isLoaded) {
    return (
      <Container>
        <PageLoading />
      </Container>
    );
  }

  //   const filterByTable = () => {
  //     return filtersHelper.filterDataTable(clientes, filters);
  //   };

  //   const clearFilters = () => {
  //     setFilters([]);
  //     setSearch("");
  //     setClientes(selectedMap.clientes);
  //   };

  //   const baseTableData = filterByTable();

  //   const backupTableData =
  //     selectedMap && selectedMap.clientesBackup ? selectedMap.clientesBackup : clientesBackupTable;

  //   //search use all clients
  //   const tableData = search
  //     ? searchHelper.searchTable(search, tableColumnsMap, clientesBackupTable)
  //     : baseTableData;

  console.log("clientes: ", clientes);

  return (
    <>
      <Container>
        {isLoaded && loadingData === false && centerMap && clientes && selectedMap ? (
          <MapContainer>
            <MapBar>
              <MapBarHeader>
                <h3>Mapas</h3>
                {isAdmin && (
                  <NewMapButton
                    onClick={() => {
                      setNewMap(true);
                      setModalFormMap(true);
                    }}
                  >
                    <MdAddCircleOutline />
                    <p>Novo mapa</p>
                  </NewMapButton>
                )}
              </MapBarHeader>
              <MapsContainer>
                {/* {mapsData.length > 0 &&
                  mapsData.map((item, index) => (
                    <AdminMapCard
                      key={index}
                      map={item}
                      selected={selectedMap.docId == item.docId ? true : false}
                      onSelect={() => {
                        setDataType("clientsMap");
                        handleSelectedMap(item);
                      }}
                      onDelete={() => {
                        setMapToDelete(item);
                        setModalDeleteMap(true);
                      }}
                      onEdit={() => {
                        setDataType("clientsMap");
                        handleSelectedMap(item);
                        setModalFormMap(true);
                      }}
                    />
                  ))} */}
              </MapsContainer>
            </MapBar>
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              clickableIcons={false}
              center={mapState.center}
              zoom={mapState.zoom}
              onIdle={(props?: GoogleMapProps) => onMapIdle(props)}
              options={{
                disableDefaultUI: true,
              }}
            >
              <MapDivRight>
                {/* <EspeciesMapFilter
                  onChangeSelected={(selectedEspecies) => {
                    if (mapRef && mapRef?.center && mapRef?.zoom) {
                      changeMapCenter(mapRef.center, mapRef.zoom);
                    }

                    setSelectedMap((prev) => {
                      const newObj = {
                        ...prev,
                        calculate: false,
                        clientes: prev.clientesBackup.filter((x) => {
                          const isEspecial = selectedEspecies.find((x) => x.especie == "ESPECIAL");

                          if (isEspecial) {
                            if (x.specialIcon) {
                              return true;
                            }
                          }

                          // if (x.retornoPremium) {
                          //   if (
                          //     arrayHelper.isPropertyValue(
                          //       selectedEspecies,
                          //       "especie",
                          //       "premiumReturn"
                          //     ) &&
                          //     !x.specialIcon
                          //   ) {
                          //     return true;
                          //   } else {
                          //     return false;
                          //   }
                          // }

                          // if (x.retornos > 0) {
                          //   if (
                          //     arrayHelper.isPropertyValue(selectedEspecies, "especie", "return") &&
                          //     !x.specialIcon
                          //   ) {
                          //     return true;
                          //   } else {
                          //     return false;
                          //   }
                          // }

                          // if (
                          //   arrayHelper.isPropertyValue(selectedEspecies, "especie", x.especie) &&
                          //   !x.specialIcon
                          // ) {
                          //   return true;
                          // }

                          return false;
                        }),
                      };

                      return newObj;
                    });
                  }}
                  calculate={selectedMap ? selectedMap?.calculate : true}
                  clearEspeciesFilter={clearEspeciesFilter}
                /> */}
                {/* {isAdmin && (
                  <AlertDiv>
                    <AlertDivHeader onClick={() => setExpandedAlert(!expandedAlert)}>
                      <div>
                        <h3>Alertas</h3>
                        <PointIcon
                          text={alertsNumber}
                          backgroundColor="var(--red)"
                          size={30}
                          textColor="var(--white)"
                          fontSize="14"
                          fontWeight="400"
                        />
                      </div>

                      {expandedAlert ? (
                        <IoIosArrowUp cursor="pointer" />
                      ) : (
                        <IoIosArrowDown cursor="pointer" />
                      )}
                    </AlertDivHeader>
                    {expandedAlert &&
                      alerts.length > 0 &&
                      alerts.map((item, index) => (
                        <Accordion
                          key={index}
                          style={{
                            boxShadow: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}-content"`}
                            id={`${index}-header"`}
                            style={{
                              marginBottom: -10,
                              gap: 10,
                              padding: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                width: "100%",
                                flexShrink: 10,
                                fontSize: 12,
                                fontFamily: "Roboto",
                              }}
                            >
                              {`${item.qtd} ${item.title}`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ paddingLeft: 0 }}
                            onClick={(event) => {
                              handleChangeData(event, item.dataType);
                            }}
                          >
                            <h4>Corrigir</h4>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AlertDiv>
                )} */}

                <ProfileButton arrowColor="white" />
              </MapDivRight>
              {!legend && (
                <HelpButton onClick={() => setLegend(true)}>
                  <BiHelpCircle size={40} cursor="pointer" />
                </HelpButton>
              )}
              {/* {legend && (
                <MarkersLegend>
                  <ModalHeader title="Legenda" onClose={() => setLegend(false)} />
                  {markerIcons &&
                    markerIcons.map((item, index) => (
                      <LegendItem key={index}>
                        <img src={item.icon} alt="img img" />
                        <h1>{item.title}</h1>
                      </LegendItem>
                    ))}
                </MarkersLegend>
              )} */}
              {markers ? (
                clientes.map((item) =>
                  item.lat ? (
                    <Marker
                      key={item.cpf}
                      position={{ lat: item.lat, lng: item[`lng`] }}
                      onClick={async () => {
                        setShowInfoWindow({ status: true, client: item.cpf });
                        setCurrentClient(item);
                        setViewDetails(true);
                      }}
                      options={{
                        icon: markersHelper.getMarkerIcons(item),
                        optimized: true,
                        animation: item.specialIcon ? window.google.maps.Animation.BOUNCE : null,
                      }}
                    >
                      {showInfoWindow.status === true && showInfoWindow.client === item.cpf && (
                        <InfoWindow
                          position={{ lat: currentClient.lat, lng: currentClient[`lng`] }}
                          onCloseClick={() => setShowInfoWindow({ status: false, client: null })}
                        >
                          <CustomInfoWindow>
                            <InfoWindowTextContainer>
                              <h4>{currentClient.name}</h4>
                            </InfoWindowTextContainer>
                          </CustomInfoWindow>
                        </InfoWindow>
                      )}
                    </Marker>
                  ) : null
                )
              ) : (
                <></>
              )}
              {/* {operatorsLocation &&
                operatorsLocation.map((item, index) => {
                  const diff = dateNow.getTime() - item.time;

                  if (diff <= 2 * (3600 * 1000)) {
                    return (
                      <Marker
                        position={{ lat: item.lat, lng: item[`lng`] }}
                        options={{
                          icon: carIcon,
                          optimized: true,
                        }}
                        key={index}
                      >
                        <InfoWindow position={{ lat: item.lat, lng: item[`lng`] }}>
                          <OperatorLocation operator={item} users={user.users} />
                        </InfoWindow>
                      </Marker>
                    );
                  }
                })} */}
              {/* {streetView === true && (
                <StreetViewPanorama
                  position={{ lat: currentClient.lat, lng: currentClient.lng }}
                  visible={streetView}
                />
              )} */}
              {/* {currentClient && viewDetails && (
                <ClientDetailsAdmin
                  data={currentClient}
                  onClose={() => {
                    setViewDetails(false);
                    setCurrentClient("");
                    setShowInfoWindow({ status: false, cliente: null });
                    setSearch("");
                    setClientes(selectedMap.clientes);
                  }}
                  onStreetView={async () => {
                    if (streetView == true) {
                      setStreetView(false);
                      setTimeout(async () => {
                        setStreetView(true);
                      }, 500);
                    } else {
                      setStreetView(!streetView);
                    }
                  }}
                  onGenerateReport={() => {
                    setModalReport(true);
                  }}
                  onViewFullData={() => {
                    setModalFullData(true);
                  }}
                  onChangeSpecialIcon={() => {
                    setSelectedMap((prev) => ({
                      ...prev,
                      calculate: false,
                      clientes: prev.clientesBackup,
                      clientesBackup: prev.clientesBackup,
                    }));
                    setClearEspeciesFilter(!clearEspeciesFilter);
                  }}
                />
              )} */}
            </GoogleMap>
          </MapContainer>
        ) : (
          <Container></Container>
        )}
        {/* <TableContainer>
          <CustomTable
            tableRows={tableData}
            tableRowsBackup={backupTableData}
            tableColumns={tableColumnsMap}
            filterTypes={tableColumnsMap}
            itemType="map"
            dateField="time"
            filtersHighlights={[{ field: "cidadeMap", headerName: "Cidade" }]}
            columns={6}
            tableType={dataType}
            onClick={(client) => {
              setShowInfoWindow({ status: true, cliente: client.cpf });
              setCurrentClient(client);
              setViewDetails(true);
              setClientes([client]);
            }}
            onChangeTableType={(newDataType) => {
              clearFilters();
              setDataType(newDataType);
              switch (newDataType) {
                case "clientsMap":
                  setClientes(clientesBackup);
                  setClientesBackupTable(clientesBackup);
                  break;
                case alertOptions[0].value:
                  getEndNotFound();
                  break;
                case alertOptions[1].value:
                  getEndNotFound();
                  break;
                case alertOptions[2].value:
                  getDifferentLocation();
                  break;
              }
            }}
            search={search}
            setSearch={setSearch}
            filters={filters}
            onChangeFilters={(newFiltersArray) => {
              setFilters(newFiltersArray);
            }}
            onClearFilters={() => clearFilters()}
          />
        </TableContainer> */}
      </Container>

      {/* <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin
          data={currentClient}
          onClose={() => setModalFullData(false)}
          onStreetView={() => {
            setModalFullData(false);
            if (streetView == true) {
              setStreetView(false);
              setTimeout(async () => {
                setStreetView(true);
              }, 500);
            } else {
              setStreetView(!streetView);
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={modalFormMap}
        onRequestClose={() => {
          setModalFormMap(false);
          setNewMap(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalFormMap
          title={!newMap ? "Editar mapa" : "Novo mapa"}
          data={!newMap ? selectedMap : null}
          loading={modalLoading}
          optionsCities={optionsCities}
          onSave={(dataMap) => {
            if (newMap) {
              createMap(dataMap);
            } else {
              updateMap(dataMap);
            }
          }}
          onClose={() => {
            setModalFormMap(false);
            setNewMap(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalDeleteMap}
        onRequestClose={() => {
          setModalDeleteMap(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDeleteMap
          mapName={mapToDelete.mapName}
          loading={modalLoading}
          onConfirm={() => {
            deleteMap();
          }}
          onCancel={() => {
            setModalDeleteMap(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalReport}
        onRequestClose={() => {
          setModalReport(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalReportAdmin
          data={currentClient}
          onClose={() => setModalReport(false)}
          onSaved={() => {
            setViewDetails(false);
            setShowInfoWindow({ status: false, client: null });
            setCurrentClient("");
          }}
        />
      </Modal> */}
    </>
  );
};

export default MapPage;
