import { MapClientsStore } from "@domainStores/MapClientsStore";
import { create } from "zustand";

const mapClientsStore = create<MapClientsStore>((set) => ({
  mapClients: [],
  updateMapClients: (newMapClients) =>
    set((state) => {
      return {
        ...state,
        mapClients: newMapClients,
      };
    }),
}));

export default mapClientsStore;
