import DatabaseClientRepository from "@domainRepositories/DatabaseClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Paging } from "@models/Paging";

export type GetTableAttendClientsInput = {
  paging: Paging;
};

export type GetTableAttendClientsOutput = void;

export class GetTableAttendClientsUseCase
  implements UseCase<GetTableAttendClientsInput, GetTableAttendClientsOutput>
{
  private readonly DatabaseClientRepository: DatabaseClientRepository;

  constructor(DatabaseClientRepository: DatabaseClientRepository) {
    this.DatabaseClientRepository = DatabaseClientRepository;
  }
  execute({ paging }: GetTableAttendClientsInput) {
    this.DatabaseClientRepository.getDashboardTableAttendClient(paging);
  }
}
