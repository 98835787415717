import UserRepository from "@domainRepositories/UserRepository";
import { HttpClient } from "@infra/api/HttpClient";
import AppError from "@infra/errors/AppError";
import { User } from "@models/User";

export class HttpUserRepository implements UserRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/users`;

  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async get(id: string) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${id}`,
        method: "GET",
      })
      .then((response) => response.user as User)
      .catch((error) => {
        throw new AppError(`Error on get user data`, error);
      });
  }
}
