import axios, { AxiosError, AxiosResponse } from "axios";
import { HttpClient, HttpRequest, HttpResponse } from "../HttpClient";
import showToast from "../../../presentation/helpers/showToast";
import ApiError from "@infra/errors/ApiError";
import userStore from "@stores/user";

export class AxiosHttpClientAdapter implements HttpClient {
  async request(data: HttpRequest) {
    let axiosResponse: AxiosResponse;

    try {
      const token = await userStore.getState().firebaseUser.getIdToken(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: headers,
      });

      return axiosResponse.data as HttpResponse;
    } catch (error) {
      const _error = error as AxiosError<{ message: string }>;

      const statusCode = _error?.response?.status;
      const message = _error?.response?.data?.message
        ? _error?.response?.data?.message
        : "Ocorreu um erro. Tente novamente mais tarde";

      showToast(message, "error");

      throw new ApiError(statusCode, message);
    }
  }
}
