import React from "react";

import { Content } from "./styles";

type CardContentProps = {
  color: string;
  value: string | number;
  description: string;
  Avatar?: React.ReactNode;
};

function CardContent({ color, value, description, Avatar }: CardContentProps) {
  return (
    <Content color={color}>
      <h1 className="value">{value}</h1>
      <h1 className="description">{description}</h1>
      {Avatar}
    </Content>
  );
}

export default CardContent;
