import React, { useState } from "react";

import {
  Container,
  ContainerLogin,
  FieldText,
  ImageLogin,
  LoadingContainer,
  LoginButton,
  LoginForm,
  LogoContainer,
  ShowPassword,
  TextInput,
  WrapLogin,
} from "./styles";

//Utils
import DotLoader from "react-spinners/ClipLoader";

//Images
import logo from "@assets/logoCentral.svg";
import imageLogin from "@assets/imageLogin.svg";
import showToast from "../../helpers/showToast";
import useUser from "@hooks/useUser";

const LoginPage = () => {
  const { loading, loginUser } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleInputChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  //   const tryLogin = async (email, password) => {
  // await userLogin(email, password);

  // if (success) {
  //   if (role === USER_TYPE.OPERATOR) {
  //     navigate("/map");
  //   } else {
  //     navigate("/dashboard");
  //   }
  // } else {
  //   if (errorCode === "auth/user-disabled") {
  //     return toast(`Conta desativada`, alertErrorStyle);
  //   }

  //   toast(`Usuário ou senha incorretos!`, alertErrorStyle);
  // }
  //   };

  const handleLogin = async () => {
    if (!email || !password) {
      return showToast("Preencha todos os campos", "error");
    }

    await loginUser(email, password);

    // if (email.match(/@/)) {
    //   await tryLogin(email, password);
    // } else {
    //   if (savedLogins.length !== 0) {
    //     const findLogin = savedLogins.find((x) => x.login === email);

    //     if (findLogin) {
    //       await tryLogin(findLogin.email, password);
    //     } else {
    //       showToast("Por favor use o email", "error");
    //     }
    //   }
    // }
  };

  return (
    <Container>
      <ImageLogin>
        <img style={{ resize: "inherit" }} src={imageLogin} alt="Login Logo" />
      </ImageLogin>
      <ContainerLogin>
        <WrapLogin>
          <LogoContainer>
            <img style={{ height: "100%", width: "100%" }} src={logo} alt="Login Logo" />
          </LogoContainer>
          <LoginForm onSubmit={() => handleLogin()}>
            <FieldText>E-mail</FieldText>
            <TextInput>
              <input name="email" onChange={handleInputChangeEmail} placeholder="E-mail" />
            </TextInput>

            <FieldText>Senha</FieldText>
            <TextInput>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={handleInputChangePassword}
                placeholder="Senha"
              />
            </TextInput>

            <ShowPassword onClick={() => setShowPassword(!showPassword)}>
              <p>{showPassword ? "Ocultar senha" : "Mostrar senha"}</p>
            </ShowPassword>

            {loading ? (
              <LoadingContainer>
                <DotLoader size={50} color={"#9a4749"} loading={loading} speedMultiplier={1.5} />
              </LoadingContainer>
            ) : (
              <LoginButton onClick={() => handleLogin()}>
                <p>Entrar</p>
                <button onClick={() => handleLogin()}></button>
              </LoginButton>
            )}
          </LoginForm>
        </WrapLogin>
      </ContainerLogin>
    </Container>
  );
};

export default LoginPage;
