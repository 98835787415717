export const arrayHelper = {
  reduceToSimple,
  ordenateArrayAsc,
  ordenateArrayDesc,
  addSelectedField,
  insertObjectOnArray,
  isPropertyValue,
  arrayToString,
  includeOnArrayObject,
  removeItemOfArray,
  replaceItemOfArray,
  findInArray,
  difference,
  removeDuplicates,
  getBalance,
};

function reduceToSimple<T>(array: T[], field: keyof T) {
  const arr = [];

  if (array) {
    for (const item of array) {
      if (item[field]) {
        const result = arr.find((x) => x[field] === item[field]);

        if (!result) {
          arr.push(item[field]);
        }
      } else {
        arr.push(item);
      }
    }
  }

  return arr as string[];
}

function ordenateArrayDesc<T>(array: T[], field: keyof T) {
  const ordenatedArray = array.sort(function (a, b) {
    return a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0;
  });

  return ordenatedArray as T[];
}

function ordenateArrayAsc<T>(array: T[], field: keyof T) {
  const ordenatedArray = array.sort(function (a, b) {
    return a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
  });

  return ordenatedArray as T[];
}

type WithSelectedField<T> = T & { selected: boolean };
function addSelectedField<T>(array: T[], defaultValue: boolean): WithSelectedField<T>[] {
  if (array.length === 0) {
    return [];
  }

  return array.map((item) => ({
    ...item,
    selected: defaultValue,
  }));
}

function insertObjectOnArray<T>(array: T[], index: number, object: T) {
  if (array.length === 0) {
    return array;
  }

  const array1 = array.slice(0, index);
  const array2 = array.slice(index + 1, array.length);

  array1.push(object);

  return array1.concat(array2);
}

function isPropertyValue<T>(array: T[], property: keyof T, value: string) {
  if (array.length === 0) {
    return false;
  }

  return array.find((x) => x[property] === value);
}

function arrayToString(array: string[], separator: string) {
  let string = "";

  for (const item of array) {
    if (string === "") {
      string = item;
    } else {
      string = string + separator + item;
    }
  }

  return string;
}

function includeOnArrayObject<T>(array: T[], property: string, string: string) {
  return array.find((x) => x[property].includes(string));
}

function findInArray(array: string[], string: string) {
  return array.find((x) => x === string);
}

function removeItemOfArray(array, index) {
  const array1 = array.slice(0, index);
  const array2 = array.slice(index + 1, array.length);

  return array1.concat(array2);
}

function replaceItemOfArray(array, index, object) {
  const array1 = array.slice(0, index);
  const array2 = array.slice(index + 1, array.length);

  array1.push(object);

  return array1.concat(array2);
}

function difference<T>(array1: T[], array2: T[]): T[] {
  const find1 = array1.filter((x) => !array2.includes(x));
  const find2 = array2.filter((x) => !array1.includes(x));

  return removeDuplicates(find1.concat(find2));
}

function removeDuplicates<T>(array: T[]): T[] {
  return [...new Set(array)];
}

function getBalance<T>(oldArray: T[], newArray: T[], field: keyof T): { added: T[]; removed: T[] } {
  const arrayAdded: T[] = [];
  const arrayRemoved: T[] = [];

  for (const item of newArray) {
    const findOld = oldArray.find((x) => x[field] === item[field]);
    if (!findOld) {
      arrayAdded.push(item);
    }
  }

  for (const item of oldArray) {
    const findNew = newArray.find((x) => x[field] === item[field]);
    if (!findNew) {
      arrayRemoved.push(item);
    }
  }

  return { added: arrayAdded, removed: arrayRemoved };
}
