import { useEffect } from "react";
import { userRoles } from "@models/User";
import useUser from "./useUser";
import { useStore } from "zustand";
import userStore from "@stores/user";

export default function useAuthentication() {
  const store = useStore(userStore);
  const { loading, checkUserLogin } = useUser();

  const { user } = store;

  const isAdmin = user?.role === userRoles.admin || user?.role === userRoles.root ? true : false;
  const isManager = user?.role === userRoles.manager ? true : false;

  useEffect(() => {
    if (loading === false) {
      checkUserLogin();
    }
  }, []);

  return {
    loading: store.loading,
    user,
    isAdmin,
    isManager,
  };
}
