import SearchClientRepository from "@domainRepositories/SearchClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { ChartDataResponse } from "@models/ChartDataResponse";
import { Filter } from "@models/Filters";

export type GetChartClientsInput = {
  filters: Filter[];
  monthsInterval: { month: number; year: number; startTime: number; endTime: number }[];
};

export type GetChartClientsOutput = Promise<ChartDataResponse[]>;

export class GetChartClientsUseCase
  implements UseCase<GetChartClientsInput, GetChartClientsOutput>
{
  constructor(private readonly searchClientRepository: SearchClientRepository) {}

  async execute({ filters, monthsInterval }: GetChartClientsInput) {
    const array: ChartDataResponse[] = [];

    for (const interval of monthsInterval) {
      let textFilters = ``;
      const facetFilters = [];

      let stringFilter = textFilters.length > 0 ? " AND " : "";

      stringFilter =
        stringFilter + `(lastReportDate:${interval.startTime} TO ${interval.endTime})` + "";

      textFilters = textFilters + stringFilter;

      for (const filter of filters) {
        if (filter.type !== "date") {
          if (filter.values?.length > 0) {
            let stringFilter = textFilters.length > 0 ? " AND (" : "(";

            for (const index in filter.values) {
              if (parseInt(index) === 0) {
                stringFilter = stringFilter + `${filter.field}:"${filter.values[index].value}"`;
              } else {
                stringFilter =
                  stringFilter + " OR " + `${filter.field}:"${filter.values[index].value}"`;
              }
            }
            stringFilter = stringFilter + ")";

            textFilters = textFilters + stringFilter;
          }
        }
      }

      const response = await this.searchClientRepository.searchClients(
        "",
        textFilters,
        facetFilters,
        1,
        1
      );

      const clients = response.total;

      array.push({
        month: interval.month,
        year: interval.year,
        value: clients,
      });
    }

    return array;
  }
}
