import { useEffect } from "react";
// import { AuthContext } from "../contexts/AuthContext";
// import { mappers } from "../helpers/mappers";

import { collection, query, onSnapshot, where, orderBy, Timestamp } from "firebase/firestore";
import { firebase } from "@infra/firebase/config";
// import { arrayHelper } from "../helpers/arrayHelper";
// import { UsersContext } from "../contexts/UsersContext";
// import { alertSuccessStyle } from "../resources/alertSuccessStyle";
// import { toast } from "react-toastify";
// import { ClientsContext } from "../contexts/ClientsContext";
// import { ReportsContext } from "../contexts/ReportsContext";
import { mappersHelper } from "../helpers/mappers";
import { clientReturnStatus, Client } from "@models/Client";
import { useStore } from "zustand";
import returnStore from "@stores/return";

// const date = new Date();

// const numberContracts = 0;

export default function useReturns() {
  // const { user, isAdmin, isManager } = useContext(AuthContext);

  // const { users } = useContext(UsersContext);
  const { returns, updateReturnsRaw } = useStore(returnStore);
  // const { reports, setReports, reportsUser, setReportsUser } = useContext(ReportsContext);

  const getAllReturns = async () => {
    const startDate = Timestamp.fromDate(new Date(1727740800000)); //Novo banco

    const consulta = query(
      collection(firebase.db, "clientsProd"),
      orderBy("updatedAt", "desc"),
      where("updatedAt", ">=", startDate),
      where("returnStatus", "in", [
        clientReturnStatus.COMPLETED,
        clientReturnStatus.EXPIRED,
        clientReturnStatus.PREMIUM,
        clientReturnStatus.PENDING,
      ])
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = mappersHelper.mapFirebaseDoc<Client>(querySnapshot);

        const completed = [];
        const expired = [];
        const premium = [];
        const pending = [];

        for (const client of mappedDocs) {
          switch (client.returnStatus) {
            case clientReturnStatus.COMPLETED:
              completed.push(client);
              break;
            case clientReturnStatus.EXPIRED:
              expired.push(client);
              break;
            case clientReturnStatus.PREMIUM:
              premium.push(client);
              break;
            case clientReturnStatus.PENDING:
              pending.push(client);
              break;
            default:
              break;
          }
        }

        const data = {
          completed: completed,
          expired: expired,
          premium: premium,
          pending: premium.concat(pending),
        };

        updateReturnsRaw(data);
      },
      (error) => console.log("getAllReturns error", error)
    );
  };

  // const getAttendClientsManager = async (operatorsArray) => {
  //   if (operatorsArray.length === 0) {
  //     setAttendClients([]);
  //     setReports([]);
  //     return;
  //   }

  //   const operatorsUid = arrayHelper.reduceToSimple(operatorsArray, "uid");

  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("operatorUid", "in", operatorsUid),
  //     orderBy("time", "desc")
  //   );
  //   onSnapshot(
  //     consulta,
  //     (querySnapshot) => {
  //       const mappedDocs = mappers.mapFirebaseDoc(querySnapshot);

  //       const mappedAttendClients = mappers.mapAttendClients(mappedDocs);

  //       setAttendClients(arrayHelper.ordenateArrayDesc(mappedAttendClients, "time"));
  //       setReports(mappedDocs);
  //     },
  //     (error) => {
  //       console.log("getAttendClientsManager error: ", error);
  //     }
  //   );
  // };

  // const getReportsUser = async (user) => {
  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("operatorUid", "==", user.uid),
  //     where("result", "==", "Retornar")
  //   );
  //   await onSnapshot(
  //     consulta,
  //     (querySnapshot) => {
  //       const mappedDocs = mappers.mapFirebaseDoc(querySnapshot);

  //       setReportsUser(arrayHelper.ordenateArrayDesc(mappedDocs, "time"));
  //     },
  //     (error) => {
  //       console.log("getReportsUser error: ", error);
  //     }
  //   );
  // };

  // //Notificações web de novos contratos
  // const checkNewContracts = async () => {
  //   const timeToCompare = new Date(`${date.getMonth() + 1}-1-${date.getFullYear()}`);
  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("result", "in", ["Contrato fechado", "Contrato fechado por indicação"])
  //   );
  //   await onSnapshot(consulta, (querySnapshot) => {
  //     const database = [];

  //     const data = querySnapshot.docs;

  //     for (const i in data) {
  //       database.push(data[i].data());
  //     }

  //     if (numberContracts != 0 && database.length > numberContracts) {
  //       toast("Novo contrato fechado", alertSuccessStyle);
  //     }

  //     numberContracts = database.length;
  //   });
  // };

  // useEffect(() => {
  //   if (isAdmin) {
  //     if (!attendClients) {
  //       getAttendClientsAdmin();
  //       checkNewContracts();
  //     }
  //   }

  //   if (isManager) {
  //     if (users && !attendClients) {
  //       getAttendClientsManager(users.operators);
  //       checkNewContracts();
  //     }
  //   }

  //   if (!isAdmin && !isManager) {
  //     if (!reportsUser && user) {
  //       getReportsUser(user);
  //     }
  //   }
  // }, [users]);

  useEffect(() => {
    if (returns.completed.length === 0) {
      getAllReturns();
    }
  }, []);

  return {
    returns: returns,
    // reports: reports,
    // reportsUser: reportsUser,
    // loadingAttendClients: !attendClients || !reports || !reportsUser ? true : false,
  };
}
