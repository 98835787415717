import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  max-width: 80vw;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 0 10px;

  height: 20%;

  user-select: none;

  color: #4a5e83;

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #4a5e83;
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #808b9f;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10px;
  }
`;

type TListContainerProps = { height: string };
export const ListContainer = styled.div<TListContainerProps>`
  /* display: flex;
    flex-direction: column; */
  margin-top: 20px;
  gap: 10px;

  height: ${(props) => props.height};
  overflow-y: auto;
`;

export const RankingHeader = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;
  justify-content: center;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    margin: 5px 0;

    /* 1 */

    color: #4a5e83;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* 2 */

    color: #808b9f;
  }
`;

export const Circle = styled.div`
  display: flex;
  position: relative;
  bottom: -30px;
  left: 30px;

  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;

  border-radius: 100px;

  background: var(--red);
  z-index: 5000;
`;
