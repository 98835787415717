import { alertErrorStyle } from "../resources/alertErrorStyle";
import { alertSuccessStyle } from "../resources/alertSuccessStyle";
import { alertWarningStyle } from "../resources/alertWarningStyle";
import { toast } from "react-toastify";

type toastType = "error" | "warn" | "success";

export default function showToast(message: string, type: toastType) {
  let style = alertErrorStyle;

  switch (type) {
    case "warn":
      style = alertWarningStyle;
      break;
    case "success":
      style = alertSuccessStyle;
      break;
    default:
      break;
  }

  toast(message, style);
}
