import DatabaseClientRepository from "@domainRepositories/DatabaseClientRepository";
import { firebase } from "@infra/firebase/config";
import { Client, clientStatus } from "@models/Client";
import { Paging } from "@models/Paging";
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { fireabseAdapter } from "./adapters/firebase";
import { AttendClientsStore } from "@stores/attendClients";
import { MapClientsStore } from "@domainStores/MapClientsStore";

export class FirebaseClientRepository implements DatabaseClientRepository {
  constructor(
    private readonly attendClientsStore: AttendClientsStore,
    private readonly mapClientsStore: MapClientsStore
  ) {}

  getDashboardCardsAttendClients(startDate: Timestamp, endDate: Timestamp) {
    const consulta = query(
      collection(firebase.db, "clientsProd"),
      orderBy("lastReportDate", "desc"),
      where("lastReportDate", ">=", startDate),
      where("lastReportDate", "<=", endDate),
      where("status", "==", clientStatus.ATTEND)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);
        this.attendClientsStore.updateDashboardCard(mappedDocs);
      },
      (error) => console.log("getDashboardCardsAttendClients error", error)
    );
  }

  getDashboardTableAttendClient(paging: Paging) {
    const consulta = query(
      collection(firebase.db, "clientsProd"),
      orderBy("lastReportDate", "desc"),
      where("status", "==", clientStatus.ATTEND),
      limit(paging.limit)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const allDocsCount = await getCountFromServer(
          query(collection(firebase.db, "clientsProd"))
        );
        this.attendClientsStore.updateDashboardTable(mappedDocs, allDocsCount.data().count);
      },
      (error) => console.log("getDashboardTableAttendClient error", error)
    );
  }

  getMapClients() {
    const consulta = query(
      collection(firebase.db, "clientsProd"),
      where("status", "not-in", [clientStatus.REMOVED, clientStatus.ATTEND])
    );
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        this.mapClientsStore.updateMapClients(mappedDocs);
      },
      (error) => console.log("getMapClients error", error)
    );
  }
}
