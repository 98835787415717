// import React, { useEffect, useState, useContext } from "react";

import { arrayHelper } from "@helpers/array";
import { dashboardHelper, CardsData } from "../../helpers/dashboard";
// import { datetimeHelper } from "../../helpers/datetime";
import useReturns from "@hooks/useReturns";
import useUser from "@hooks/useUser";
import { Client } from "@models/Client";
import { reportResultOptions } from "@models/Report";
import { User } from "@models/User";
import { useEffect, useState } from "react";
import { Container, Header } from "./styles";
import ReportsCards from "./components/ReportsCards/ReportsCards";
import Table from "@components/Table/Table";
import { tableColumns } from "@resources/tableColumns";
import useCardsAttendClients from "@hooks/useCardsAttendClients";
import useTableAttendClients from "@hooks/useTableAttendClients";
import { Filter } from "@models/Filters";
import { Paging } from "@models/Paging";
import HeaderFilter from "@components/Filters/HeaderFilter/HeaderFilter";

// import { Container, Header } from "./styles";

//Firebase and context
// import { AuthContext } from '../../contexts/AuthContext';

//Custom Components
// import OperatorsCardDashboard from "../../components/OperatorsCardDashboard";
// import CustomTable from "../../components/CustomTable";

//Utils
// import tableColumnsReturns from '../../resources/tableColumnsReturns.json';

//MUI
// import { datetime } from "../../helpers/datetime";
// import { UsersContext } from "../../contexts/UsersContext";

//utils
// import ReportsCards from "./components/ReportsCards";
// import { cards } from "../../helpers/cards";
// import useAttendClients from "../../hooks/useAttendClients";
// import { arrayHelper } from "../../helpers/arrayHelper";
// import useRoutes from "../../hooks/useRoutes";
// import { reportResultType } from "../../constants/reportResultType";
// import useExpiredReturns from "../../hooks/useExpiredReturns";
// import CustomFilterHeader from "../../components/CustomFilterHeader";
import { monthNames } from "@constants/monthNames";
import OperatorsCard from "./components/OperatorsCard/OperatorsCard";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import Charts from "./components/Charts/Charts";
// import ProfileButton from "../../components/ProfileButton";
// import { filtersHelper } from "../../helpers/filtersHelper";
// import { searchHelper } from "../../helpers/searchHelper";
// import Charts from "./components/Charts";

const dateNow = new Date();
const currentMonth = dateNow.getMonth();

const defaultFilterObj = {
  line1: [],
  line2: [],
  line3: [],
};

export type ChartsFilters = {
  line1: Filter[];
  line2: Filter[];
  line3: Filter[];
};

export type OperatorsCardData = {
  name: User["name"];
  id: User["id"];
  profileImageUrl: User["profileImageUrl"];
  daysWithRoutes: number;
  visitedClients: Client[];
  returns: {
    total: Client[];
    completed: Client[];
    expired: Client[];
    pending: Client[];
  };
};

const DashboardPage = () => {
  const [headerFilters, setHeaderFilters] = useState<Filter[]>([]);

  const { cardsAttendClients } = useCardsAttendClients({ headerFilters });

  const [selectedLine, setSelectedLine] = useState("line1");
  const [filters, setFilters] = useState<ChartsFilters>(defaultFilterObj);

  const [paging, setPaging] = useState<Paging>({ offset: 0, limit: 25 });

  const { tableAttendClients, tableAttendClientsTotal } = useTableAttendClients({
    filters: filters[selectedLine],
    paging,
  });

  const { returns } = useReturns();
  const { user, isAdmin, isManager } = useUser();

  // const listItems = dashboardHelper.convertToDashboardItem(attendClients);

  // const { expiredReturnsClients, fetchExpiredReturns } = useExpiredReturns();
  // const { routes, fetchRoutes } = useRoutes();

  //Cards
  const [operatorsCardData, seOperatorsCardData] = useState<OperatorsCardData[]>(null);
  const [cardsData, setCardsData] = useState<CardsData>(null);

  //OperatorsCard
  const [operatorsCardFilters, setOperatorsCardFilters] = useState([]);

  useEffect(() => {
    if (cardsAttendClients) {
      processReportsCards(cardsAttendClients);
      processOperatorsCard(cardsAttendClients);
    }
  }, [cardsAttendClients, headerFilters]);

  // useEffect(() => {
  //   if (cardsAttendClients) {
  //     // fetchRoutes()
  //     processOperatorsCard(cardsAttendClients);
  //   }
  // }, [cardsAttendClients]);

  const processReportsCards = (cardsAttendClients: Client[]) => {
    let adminUids = [];

    if (isAdmin) {
      adminUids = arrayHelper.reduceToSimple(user.users.admins, "id").concat([user.id]);
    }

    const notAttendByAdmin = cardsAttendClients.filter((x) => !adminUids.includes(x.operatorId));

    const data = dashboardHelper.getCardsData(notAttendByAdmin);

    setCardsData(data);
  };

  const processOperatorsCard = (cardsAttendClients: Client[]) => {
    const arrayUsers = user.users?.operators
      ? user.users.operators.filter((x) => x.disabled != true)
      : [];

    let validAttendClients = cardsAttendClients;

    if (isAdmin) {
      const adminUids = arrayHelper.reduceToSimple(user.users.admins, "id").concat([user.id]);

      validAttendClients = cardsAttendClients.filter(
        (report) => !adminUids.includes(report.operatorId)
      );
    }

    if (isManager) {
      validAttendClients = cardsAttendClients.filter((report) =>
        user.operatorsIds.includes(report.operatorId)
      );
    }

    // const findDaysWithRoutes = [];
    // const daysOfMonth = [];

    // const monthInterval = datetime.getMonthIntervalMs(dateNow.getTime());

    // for (let i = monthInterval.startTime; i <= monthInterval.endTime; i = i + 86400 * 1000) {
    //   daysOfMonth.push(i);
    // }

    // const { startTime } = datetime.getMonthIntervalMs(dateNow.getTime());
    // const monthRoutes = routes ? routes.filter((x) => x.time > startTime) : [];

    // for (const dayTime of daysOfMonth) {
    //   const result = monthRoutes.filter((x) => x.time > dayTime && x.time < dayTime + 86399 * 1000);

    //   if (result.length > 0) {
    //     findDaysWithRoutes.push({
    //       day: dayTime,
    //       routes: result,
    //     });
    //   }
    // }

    const validVisited = validAttendClients.filter(
      (x) => x.lastReportResult != reportResultOptions.DIVERSE_LOCATION
    );

    const data = dashboardHelper.geOperatorsCardData(validVisited, returns, arrayUsers);
    seOperatorsCardData(data);
  };

  const filterByOperatorCard = () => {
    const operatorUid = operatorsCardFilters[0];
    const type = operatorsCardFilters[1];

    if (operatorUid) {
      const operatorCardData = operatorsCardData.find((x) => x.id === operatorUid);

      if (operatorCardData) {
        if (type) {
          if (type === "visits") {
            return arrayHelper.ordenateArrayDesc<Client>(
              operatorCardData.visitedClients,
              "lastReportDate"
            );
          }

          if (type === "returns") {
            return arrayHelper.ordenateArrayDesc<Client>(
              operatorCardData.returns.total,
              "lastReportDate"
            );
          }
        } else {
          return arrayHelper.ordenateArrayDesc<Client>(
            operatorCardData.visitedClients.concat(operatorCardData.returns.total),
            "lastReportDate"
          );
        }
      }
    }

    return [];
  };

  // const filterByTable = () => {
  //   return filtersHelper.filterDataTable(attendClients, filters[selectedLine]);
  // };

  // const baseTableData =
  //   operatorsCardFilters.length > 0 && operatorsCardData ? filterByOperatorCard() : attendClients;

  // const backupTableData =
  //   operatorsCardFilters.length > 0 && operatorsCardData ? filterByOperatorCard() : attendClients;

  // const tableData = search
  //   ? searchHelper.searchTable(search, tableColumnsReturns, backupTableData)
  //   : baseTableData;

  // const dashboardTableData = baseTableData;

  return (
    <Container>
      <Header>
        <h1>Dashboard</h1>
        <div>
          <HeaderFilter
            filters={headerFilters}
            filterTypes={[{ headerName: "Data", field: "lastReportDate" }]}
            dateField="lastReportDate"
            onChangeFilters={(newFiltersArray) => {
              setHeaderFilters(newFiltersArray);
            }}
            onClearFilters={() => setHeaderFilters([])}
            defaultTitle={monthNames.full[currentMonth]}
          />
          <ProfileButton arrowColor="var(grey2)" />
        </div>
      </Header>

      <ReportsCards cardsData={cardsData} />

      <OperatorsCard
        operatorsData={operatorsCardData}
        onFilter={(newData) => {
          // clearFilters()
          // setOperatorsCardFilters(newData)
        }}
        unFilter={() => {
          // clearFilters()
          // setOperatorsCardFilters([])
        }}
      />

      <Charts
        filters={filters}
        selectedLine={selectedLine}
        setSelectedLine={(newLine) => setSelectedLine(newLine)}
      />

      <Table
        title="Clientes atendidos"
        tableColumns={tableColumns.dashboard}
        tableRows={tableAttendClients}
        tableRowsTotal={tableAttendClientsTotal}
        filterTypes={tableColumns.dashboard}
        filters={filters[selectedLine]}
        onChangeFilters={(newFiltersArray: Filter[]) => {
          setFilters((prev) => ({
            ...prev,
            [selectedLine]: newFiltersArray,
          }));
        }}
        paging={paging}
        onChangePaging={(paging) => setPaging(paging)}
        columns={7}
        itemType="attend"
        filtersHighlights={[
          {
            field: "lastReportDate",
            headerName: "Data",
          },
          {
            field: "operatorName",
            headerName: "Operador",
          },
          {
            field: "lastReportResult",
            headerName: "Resultado",
          },
        ]}
        dateField="lastReportDate"
      />
    </Container>
  );
};

export default DashboardPage;
