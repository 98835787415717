import React from "react";

import { Container, Name, Specie, TextResult } from "./styles";

//Icons
import cpfIcon from "@assets/cpfIcon.svg";
import { GoDotFill } from "react-icons/go";
import { BiMap } from "react-icons/bi";
import { AiOutlineCalendar } from "react-icons/ai";

//MUI
import { Avatar } from "@mui/material";
import { datetimeHelper } from "@helpers/datetime";
import { avatarHelper } from "@helpers/avatar";
import { speciesHelper } from "@helpers/species";
import { Client } from "@models/Client";

const options = [
  "Contrato fechado",
  "Contrato fechado por indicação",
  "Endereço não encontrado",
  "Litispendência",
  "Já tem advogado",
  "Retornar",
  "Não tem direito",
  "Não tem interesse",
  "Localidade diversa",
];

const optionsColors = [
  "var(--success)",
  "var(--success)",
  "var(--red)",
  "var(--red)",
  "var(--red)",
  "var(--yellow)",
  "var(--red)",
  "var(--red)",
  "var(--red)",
];

type TReturnItemProps = {
  data: Client;
  onClick: () => void;
};

function ReturnItem({ data, onClick }: TReturnItemProps) {
  return (
    <Container onClick={onClick}>
      <Name>{data.name}</Name>
      <div>
        <img src={cpfIcon} alt="cpf" />
        <h4>{data.cpf}</h4>
      </div>
      <div>
        <Avatar
          alt="Avatar"
          src={avatarHelper.getUserAvatar(data.operatorId)}
          sx={{
            width: 20,
            height: 20,
            borderRadius: 100,
            borderColor: "white",
            borderWidth: 10,
          }}
        />
        <h4>{data.operatorName}</h4>
      </div>
      <Specie
        color={speciesHelper.getSpecieTag(data.specie).tagColor}
        textColor={speciesHelper.getSpecieTag(data.specie).textColor}
      >
        {data.specie}
      </Specie>
      <div>
        <GoDotFill
          size={10}
          color={optionsColors[options.findIndex((x) => x === data.lastReportResult)]}
        />
        <TextResult color={optionsColors[options.findIndex((x) => x === data.lastReportResult)]}>
          {data.lastReportResult}
        </TextResult>
      </div>
      <div>
        <BiMap size={15} color="var(--grey2)" />
        <h4>{data.cityMap}</h4>
      </div>
      <div>
        <AiOutlineCalendar color="var(--grey2)" />
        <h4>{datetimeHelper.getFullDate(data.lastReportDate)}</h4>
      </div>
    </Container>
  );
}

export default ReturnItem;
