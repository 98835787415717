import { createGlobalStyle } from "styled-components";

const styled = { createGlobalStyle };

const GlobalStyle = styled.createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body,
  #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;

    margin: 0;
  }
  *,
  button {
    border: 0;
    background: none;
  }

  *:focus {
    outline: none;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 0;
    background: none;
    color: var(--grey2);
    border-radius: 100px;
  }
  html {
    background: var(--primary);
  }
  :root {
    --primary: #ebf3ff;
    --red: #de6161;
    /* --yellow: #FFD793; */
    --yellow: #f5c87b;
    --orange: #f5987b;
    --blue: #9bbeff;
    --aqua: #83dbd6;
    --purple: #b5aaff;
    --background: #ebf3ff;
    --grey1: #b4c1d5;
    --grey2: #808b9f;
    --blue2: #596e95;
    --blue3: #4d6289;
    --blue4: #4a5e83;
    --white: #ffffff;
    --success: #68cfc9;
    --brown: #554040;
  }
  .modal-overlay {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  }
  .modal-content {
    width: 90%;
    max-width: 500px;
    height: 80%;
    overflow-y: scroll;
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    outline: none;
    z-index: 10000;
  }
  .modal-content-auto-width {
    /* width: 100%; */
    max-height: 90%;
    /* overflow-y: scroll; */
    background-color: white;
    /* padding: 30px; */
    border-radius: 15px;
    outline: none;
    z-index: 10000;
  }
  .modal-content-auto {
    background-color: white;
    border-radius: 15px;
    outline: none;
    z-index: 10000;
  }
  .modal-content-alert {
    background-color: white;
    border-radius: 40px;
    outline: none;
    z-index: 10000;
  }
  .modal-view-route {
    height: 98vh;
    width: 90vw;
    background-color: white;
    border-radius: 40px;
    outline: none;
    z-index: 10000;
  }
  .modal-title {
    color: var(--red);
    margin-bottom: 10px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #b4c1d5;
    border-radius: 50px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a5e83;
    border-radius: 50px;
    margin-left: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4a5e83;
    border-radius: 50px;
  }
`;

export default GlobalStyle;
