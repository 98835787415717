import { HttpMapRepository } from "@dataRepositories/HttpMapRepository";
import { GetMapsUseCase } from "@dataUseCases/index";
import { api } from "@infra/api";

const getMapsUseCase = new GetMapsUseCase(new HttpMapRepository(api));

export default function useMaps() {
  const getMaps = async () => {
    return getMapsUseCase.execute();
  };
  return {
    getMaps,
  };
}
