import type { Timestamp } from "firebase/firestore";
import { Report } from "./Report";

type ClientSpecie = {
  "LOAS DEF": string;
  "LOAS IDOSO": string;
  "AUX. DOENÇA": string;
  "AUX. BRASIL": string;
  "PENSÃO POR MORTE": string;
  "AUX. DOENÇA POR ACIDENTE DO TRABALHO": string;
  "AUX. ACIDENTE": string;
};

export const clientSpecieOptions = {
  DISABILITY: "LOAS DEF",
  ELDERLY: "LOAS IDOSO",
  DISEASE: "AUX. DOENÇA",
  BRAZIL: "AUX. BRASIL",
  DEATH_PENSION: "PENSÃO POR MORTE",
  DISEASE_WORK_ACCIDENT: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
  ACCIDENT: "AUX. ACIDENTE",
} as const;

export type Address = {
  street: string;
  number: string;
  district: string;
  state: string;
  city: string;
  zipCode: string;
  complement: string;
};

export type ClientAddress = {
  S1: Address;
  S2: Address;
  O1: Address;
  O2: Address;
};

export const clientStatus = {
  IN_MAP: "IN_MAP",
  REMOVED: "REMOVED",
  ATTEND: "ATTEND",
  SELECTED: "SELECTED",
  WAITING_RETURN: "WAITING_RETURN",
  WRONG_ADDRESS: "WRONG_ADDRESS",
} as const;

export const clientReturnStatus = {
  NO_RETURN: "NO_RETURN",
  PENDING: "PENDING",
  PREMIUM: "PREMIUM",
  EXPIRED: "EXPIRED",
  COMPLETED: "COMPLETED",
} as const;

export const clientAddressStatus = {
  FOUND: "FOUND",
  NOT_FOUND: "NOT_FOUND",
  NOT_FOUND_OPERATOR: "NOT_FOUND_OPERATOR",
  DIVERSE_LOCATION: "DIVERSE_LOCATION",
} as const;

export interface Client {
  id: string;
  cpf: string;
  name: string;
  email: string;
  motherName: string;

  age: string;
  birthDate: string;
  birth65Time: string;

  cityMap: string;
  mapId: string;
  lat: number;
  lng: number;

  status: keyof typeof clientStatus;
  specie: keyof ClientSpecie;
  specialIcon: boolean;

  validAddress: keyof ClientAddress;
  addressStatus: keyof typeof clientAddressStatus;
  addresses: ClientAddress;

  phones: {
    "0": string;
    "1": string;
    "2": string;
    phone: string;
  };
  telephone: {
    "0": string;
    "1": string;
    "2": string;
    primary: string;
    secondary: string;
  };

  processes: string;
  analysis: string;
  rejection: {
    date: string;
    reason: string;
    nb: string; // Perguntar o que é
    derDate: string; // Perguntar o que é
  };

  returnStatus: keyof typeof clientReturnStatus;
  returnExpireDate: Timestamp | "";
  returnsCount: number;

  operatorId: string;
  operatorName: string;

  lastReportId: Report["id"];
  lastReportResult: Report["result"] | "";
  lastReportDate: Report["createdAt"] | "";

  createdAt: Timestamp;
  updatedAt: Timestamp;
}
