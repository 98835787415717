import { Client } from "@models/Client";
import { create } from "zustand";

export interface AttendClientsStore {
  dashboardCard: Client[];
  dashboardTable: Client[];
  dashboardTableTotal: number;
  updateDashboardCard: (newClients: Client[]) => void;
  updateDashboardTable: (newClients: Client[], total: number) => void;
}

const attendclientsStore = create<AttendClientsStore>((set) => ({
  dashboardCard: [],
  dashboardTable: [],
  dashboardTableTotal: 0,
  updateDashboardCard: (newClients) =>
    set((state) => {
      return {
        ...state,
        dashboardCard: newClients,
      };
    }),
  updateDashboardTable(newClients, total) {
    set((state) => {
      return {
        ...state,
        dashboardTable: newClients,
        dashboardTableTotal: total,
      };
    });
  },
}));

export default attendclientsStore;
