import { QuerySnapshot } from "firebase/firestore";

export const fireabseAdapter = {
  mapFirebaseDoc,
};

function mapFirebaseDoc<T>(querySnapshot: QuerySnapshot) {
  const data = querySnapshot.docs;

  return data.map((value) => {
    return { ...value.data(), id: value.id } as T;
  });
}
