import ApiError from "./ApiError";

export default class AppError {
  message: string;
  apiStatusCode?: ApiError["statusCode"];
  apiMessage?: ApiError["message"];

  constructor(message: string, apiError?: ApiError) {
    this.message = message;
    this.apiStatusCode = apiError?.statusCode;
    this.apiMessage = apiError?.message;
  }
}
