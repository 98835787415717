import { FirebaseClientRepository } from "@dataRepositories/FirebaseClientRepository";
import { GetMapClientsUseCase } from "@dataUseCases/index";
import attendclientsStore from "@stores/attendClients";
import mapClientsStore from "@stores/mapClients";
import { useEffect } from "react";
import { useStore } from "zustand";

const getMapClientsUseCase = new GetMapClientsUseCase(
  new FirebaseClientRepository(attendclientsStore.getState(), mapClientsStore.getState())
);

export default function useMapClients() {
  const { mapClients } = useStore(mapClientsStore);

  const getMapClientsAdmin = async () => {
    getMapClientsUseCase.execute();
  };

  useEffect(() => {
    if (mapClients.length === 0) {
      getMapClientsAdmin();
    }
  }, []);

  return {
    mapClients,
  };
}
