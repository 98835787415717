import { Client } from "@models/Client";
import { OperatorsCardData } from "@pages/Dashboard/DashboardPage";
import { Returns } from "@stores/return";
import { User } from "@models/User";
import { reportResultOptions } from "@models/Report";

export type CardsData = {
  signed: Client[];
  winnerSigned: {
    operatorId: string;
    operatorName: string;
    qtd: number;
    array: Client[];
  };
  visitedClients: Client[];
  winnerVisits: {
    operatorId: string;
    operatorName: string;
    qtd: number;
    array: Client[];
  };
};

export const dashboardHelper = {
  getCardsData,
  geOperatorsCardData,
  getAttendOfMonth,
};

function getCardsData(attendClients: Client[]): CardsData {
  const signed = calculateSignedContracts(attendClients);
  const winnerSigned = calculateRanking(signed, attendClients);

  const visitedClients = calculateVisitedClients(attendClients);
  const winnerVisits = calculateWinnerVisits(visitedClients, attendClients);

  return {
    signed: signed,
    winnerSigned: winnerSigned,
    visitedClients: visitedClients,
    winnerVisits: winnerVisits,
  };
}

// function convertToDashboardItem(attendClients: Client[]): TDashboardListItem[] {
//   const dashboardListItems: TDashboardListItem[] = [];

//   for (const client of attendClients) {
//     const listItem: TDashboardListItem = {
//       name: client.name,
//       cpf: client.cpf,
//       operatorName: client.operatorName,
//       operatorId: client.operatorId,
//       specie: client.specie,
//       result: client.lastReportResult,
//       city: client.addresses[client.validAddress].city,
//       date: client.lastReportDate,
//     };

//     dashboardListItems.push(listItem);
//   }

//   return dashboardListItems;
// }

function calculateSignedContracts(attendClients: Client[]): Client[] {
  return attendClients.filter(
    (x) =>
      x.lastReportResult === reportResultOptions.SIGNED ||
      x.lastReportResult === reportResultOptions.RECOMMENDATION_SIGNED
  );
}

function calculateRanking(
  signedReportsClients: Client[],
  cardsData: Client[]
): CardsData["winnerSigned"] {
  const operators = [];
  const rankingArray = [];

  for (const client of cardsData) {
    const result = operators.find((x) => x === client.operatorName);

    if (!result) {
      operators.push({
        operatorName: client.operatorName,
        operatorId: client.operatorId,
      });
    }
  }

  if (operators.length > 0) {
    for (const operator of operators) {
      const result = signedReportsClients.filter((x) => x.operatorId === operator.operatorId);

      rankingArray.push({
        operatorName: operator.operatorName,
        operatorId: operator.operatorId,
        qtd: result.length,
        array: result,
      });
    }
  }

  if (rankingArray.length > 0) {
    return rankingArray.reduce(function (prev, current) {
      return prev.qtd > current.qtd ? prev : current;
    });
  }

  return {
    operatorName: "",
    operatorId: "",
    qtd: 0,
    array: [],
  };
}

function calculateVisitedClients(attendClients: Client[]): Client[] {
  const visitedClientsMonth = [];

  for (const client of attendClients) {
    if (client.lastReportResult != reportResultOptions.DIVERSE_LOCATION) {
      visitedClientsMonth.push(client);
    }
  }

  return visitedClientsMonth;
}

function calculateWinnerVisits(
  visitedClients: Client[],
  cardsData: Client[]
): CardsData["winnerVisits"] {
  const operators = [];
  const rankingArray = [];

  for (const client of cardsData) {
    if (client.lastReportResult != reportResultOptions.DIVERSE_LOCATION) {
      const result = operators.find((x) => x === client.operatorName);

      if (!result) {
        operators.push({
          operatorName: client.operatorName,
          operatorId: client.operatorId,
        });
      }
    }
  }

  if (operators.length > 0) {
    for (const operator of operators) {
      const result = visitedClients.filter((x) => x.operatorId === operator.operatorId);

      rankingArray.push({
        operatorName: operator.operatorName,
        operatorId: operator.operatorId,
        qtd: result.length,
        array: result,
      });
    }
  }

  if (rankingArray.length > 0) {
    return rankingArray.reduce(function (prev, current) {
      return prev.qtd > current.qtd ? prev : current;
    });
  }

  return {
    operatorName: "",
    operatorId: "",
    qtd: 0,
    array: [],
  };
}

function geOperatorsCardData(
  visitedClients: Client[],
  returns: Returns,
  arrayUsers: User[]
): OperatorsCardData[] {
  const array: OperatorsCardData[] = [];

  const findDaysWithRoutes = [];

  array.push({
    name: "Geral",
    id: "geral",
    profileImageUrl: null,
    daysWithRoutes: 0,
    visitedClients: visitedClients,
    returns: {
      total: returns.completed.concat(returns.expired, returns.pending),
      completed: returns.completed,
      expired: returns.expired,
      pending: returns.pending,
    },
  });

  for (const user of arrayUsers) {
    let totalDays = 0;

    for (const day of findDaysWithRoutes) {
      const result = day.routes.find((x) => x.uid === user.id);

      if (result) {
        totalDays = totalDays + 1;
      }
    }
    const findVisitedClients = visitedClients.filter((x) => x.operatorId === user.id);
    const findRealizedReturns = returns.completed.filter((x) => x.operatorId === user.id);
    const findExpiredReturns = returns.expired.filter((x) => x.operatorId === user.id);
    const findPendingReturns = returns.pending.filter((x) => x.operatorId === user.id);

    array.push({
      name: user.name,
      id: user.id,
      profileImageUrl: user.profileImageUrl,
      daysWithRoutes: totalDays,
      visitedClients: findVisitedClients,
      returns: {
        total: findRealizedReturns.concat(findPendingReturns, findExpiredReturns),
        completed: findRealizedReturns,
        expired: findExpiredReturns,
        pending: findPendingReturns,
      },
    });
  }

  return array;
}

function getAttendOfMonth(attendClients: Client[]) {
  const dateNow = new Date();
  const currentMonth = dateNow.getMonth();

  const result = attendClients.filter(
    (x) =>
      x.lastReportDate &&
      new Date(x.lastReportDate.seconds * 1000).getMonth() === currentMonth &&
      new Date(x.lastReportDate.seconds * 1000).getFullYear() === dateNow.getFullYear()
  );

  return result;
}
