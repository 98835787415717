import { Timestamp } from "firebase/firestore";
import { Address, Client } from "./Client";

type ReportResult = {
  "Contrato fechado": string;
  "Contrato fechado por indicação": string;
  "Endereço não encontrado": string;
  Litispendência: string;
  "Já tem advogado": string;
  Retornar: string;
  "Não tem direito": string;
  "Não tem interesse": string;
  "Localidade diversa": string;
};

export const reportResultOptions = {
  SIGNED: "Contrato fechado",
  RECOMMENDATION_SIGNED: "Contrato fechado por indicação",
  ADDRESS_NOT_FOUND: "Endereço não encontrado",
  PENDENCY: "Litispendência",
  HAVE_LAWYER: "Já tem advogado",
  RETURN: "Retornar",
  NO_RIGHT: "Não tem direito",
  NOT_INTERESSED: "Não tem interesse",
  DIVERSE_LOCATION: "Localidade diversa",
} as const;

export interface Report {
  id: string;
  result: keyof ReportResult;
  description: string;

  clientName: string;
  clientCpf: string;
  clientSpecie: Client["specie"];

  operatorName: string;
  operatorId: string;

  routeId: string;

  clientAddress: Address;
  clientValidAddress: Client["validAddress"];

  returnDate: string;
  returnHour: string;
  returnsCount: number;

  createdAt: Timestamp;
  updatedAt: Timestamp;
}
