import React, { useState } from "react";

import {
  Container,
  Content,
  ActionsContent,
  CancelButton,
  ConfirmButton,
  ModalConfirmText,
  Divider,
} from "./styles";

import deleteIcon from "@assets/deleteIcon.svg";
import Loading from "../../Loading/Loading";
import { Client } from "@models/Client";

type ModalDeleteUserProps = {
  client: Client;
  onCancel: () => void;
  onSuccess: () => void;
};

const ModalDeleteUser = ({ client, onCancel, onSuccess }: ModalDeleteUserProps) => {
  //   const { removeFromMap } = useContext(MapsContext);

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    // const result = await removeFromMap(client);
    const result = true;
    setLoading(false);

    if (result) {
      onSuccess();
    } else {
      onCancel();
    }
  };

  return (
    <Container>
      <Content>
        <img src={deleteIcon} style={{ height: 80, width: 80 }} alt="map map" />
        <ModalConfirmText>Tem certeza que deseja excluir {client.name} do mapa?</ModalConfirmText>
      </Content>
      <Divider />
      <ActionsContent>
        {loading ? (
          <Loading />
        ) : (
          <>
            <CancelButton onClick={onCancel}>
              <p>Voltar</p>
            </CancelButton>
            <ConfirmButton onClick={() => handleConfirm()}>
              <p>Excluir</p>
            </ConfirmButton>
          </>
        )}
      </ActionsContent>
    </Container>
  );
};

export default ModalDeleteUser;
