import MapRepository from "@domainRepositories/MapRepository";
import { HttpClient } from "@infra/api/HttpClient";
import AppError from "@infra/errors/AppError";
import { Map } from "@models/Map";

export class HttpMapRepository implements MapRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/maps`;

  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/get-all-maps`,
        method: "GET",
      })
      .then((response) => response.maps as Map[])
      .catch((error) => {
        throw new AppError(`Error on get maps`, error);
      });
  }
}
