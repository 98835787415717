import React from "react";

//pages
import DashboardPage from "@pages/Dashboard/DashboardPage";
import MapPage from "@pages/Map/MapPage";

//react route
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { PrivateRoute } from "./PrivateRoute";

//Util
// import MenuBar from '../components/MenuBar';
import LoginPage from "@pages/Login/Login";
import { AdminRoute } from "./AdminRoute";
import { DefaultRoute } from "./DefaultRoute";
import MenuBar from "@components/MenuBar/MenuBar";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <DashboardPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/adminMapPage"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <MapPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/"
          element={
            <DefaultRoute>
              <LoginPage />
            </DefaultRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
