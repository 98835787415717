import { markerIcons } from "../resources/markerIcons";

import birthday from "../assets/markers/birthday34.png";
import retornoIcon from "../assets/markers/retorno34.png";
import retornoPremium from "../assets/markers/retornoPremium34.png";
import money34 from "../assets/markers/money34.gif";
import minor from "../assets/markers/menores34.png";
import { Client, clientReturnStatus } from "@models/Client";

export const markersHelper = {
  getMarkerIcons,
  getMarkerIconsEspecie,
};

function getMarkerIcons(client: Client) {
  if (client.specialIcon) {
    return money34;
  }

  if (parseInt(client.age) < 18) {
    return minor;
  }

  const hoje = new Date();

  const dia = client.birthDate.slice(0, 2);
  const month = Number(client.birthDate.slice(3, 5));
  const ano = Number(client.birthDate.slice(-4)) + 65;

  let alertMonth;
  if (month - 6 < 0) {
    alertMonth = 12 + (month - 6);
  } else {
    alertMonth = month - 6;
  }

  const dateAlert = new Date(`${alertMonth}-20-${ano}`);
  const dateBirthday = new Date(`${month}-${dia}-${ano}`);

  if (client.returnsCount > 0) {
    if (client.returnStatus === clientReturnStatus.PREMIUM) {
      return retornoPremium;
    } else {
      return retornoIcon;
    }
  }

  if (hoje.getTime() >= dateAlert.getTime() && hoje.getTime() <= dateBirthday.getTime()) {
    return birthday;
  }

  const index = markerIcons.findIndex((x) => x.specie === client.specie);

  return markerIcons[index].icon;
}

function getMarkerIconsEspecie(specie: Client["specie"]) {
  const index = markerIcons.findIndex((x) => x.specie === specie);

  if (index != -1) {
    return markerIcons[index].icon;
  }

  return null;
}
