//Icons map
import defIcon from "@assets/markers/def34.png";
import idosoIcon from "@assets/markers/idoso34.png";
import auxDoenca from "@assets/markers/auxDoenca34.png";
import auxBrasil from "@assets/markers/auxBrasil34.png";
import pensaoPorMorte from "@assets/markers/pensaoPorMorte34.png";
import auxDoencaAcidente from "@assets/markers/auxDoencaAcidente34.png";
import auxAcidente from "@assets/markers/auxAcidente34.png";
import excelente from "@assets/markers/money34.gif";
import birthday from "@assets/markers/birthday34.png";
import retornoIcon from "@assets/markers/retorno34.png";
import retornoPremium from "@assets/markers/retornoPremium34.png";

//Variables
export const markerIcons = [
  {
    specie: "LOAS DEF",
    icon: defIcon,
    title: "LOAS DEFICIENTE",
  },
  {
    specie: "LOAS IDOSO",
    icon: idosoIcon,
    title: "LOAS IDOSO",
  },
  {
    specie: "AUX. DOENÇA",
    icon: auxDoenca,
    title: "AUX. DOENÇA",
  },
  {
    specie: "AUX. BRASIL",
    icon: auxBrasil,
    title: "BOLSA FAMÍLIA",
  },
  {
    specie: "PENSÃO POR MORTE",
    icon: pensaoPorMorte,
    title: "PENSÃO POR MORTE",
  },
  {
    specie: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
    icon: auxDoencaAcidente,
    title: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
  },
  {
    specie: "AUX. ACIDENTE",
    icon: auxAcidente,
    title: "AUX. ACIDENTE",
  },
  {
    specie: "EXCELENTE",
    icon: excelente,
    title: "EXCELENTE",
  },
  {
    specie: "BIRTHDAY",
    icon: birthday,
    title: "6 MESES PARA O ANIVERSÁRIO DE 65 ANOS",
  },
  {
    specie: "RETORNO",
    icon: retornoIcon,
    title: "RETORNO",
  },
  {
    specie: "RETORNO PREMIUM",
    icon: retornoPremium,
    title: "RETORNO ATRIBUÍDO PELO GERENTE",
  },
];
