import React from "react";

import { Container, Name, Specie, Date, Options } from "./styles";

//Icons
import { AiOutlineEye } from "react-icons/ai";

//Custom components
import Divider from "@components/Divider/Divider";

//MUI
import { Avatar } from "@mui/material";

//utils
import { datetimeHelper } from "@helpers/datetime";
import { avatarHelper } from "@helpers/avatar";
import { speciesHelper } from "@helpers/species";
import { Client } from "@models/Client";

export type ModalDashboardItemProps = {
  data: Client;
  showAvatar: boolean;
  columns: number;
  onViewFullData: () => void;
};

function ModalDashboardItem({
  data,
  showAvatar,
  columns,
  onViewFullData,
}: ModalDashboardItemProps) {
  return (
    <>
      <Container columns={columns}>
        <Name>{data.name}</Name>
        <Specie
          color={speciesHelper.getSpecieTag(data.specie).tagColor}
          textColor={speciesHelper.getSpecieTag(data.specie).textColor}
        >
          {data.specie}
        </Specie>
        <Date color="var(--grey2)">{datetimeHelper.getDate(data.lastReportDate)}</Date>
        {showAvatar && (
          <div>
            <Avatar
              alt="Avatar"
              src={avatarHelper.getUserAvatar(data.operatorId)}
              sx={{
                width: 20,
                height: 20,
                borderRadius: 100,
                borderColor: "white",
                borderWidth: 10,
              }}
            />
            <h4>{data.operatorName}</h4>
          </div>
        )}
        <Options>
          <AiOutlineEye
            onClick={onViewFullData}
            cursor="pointer"
            size={20}
            color="#808B9F"
            fill="#808B9F"
          />
        </Options>
      </Container>
      <Divider width="99%" />
    </>
  );
}

export default ModalDashboardItem;
