import { Report } from "@models/Report";
import { QuerySnapshot } from "firebase/firestore";

export const mappersHelper = {
  mapFirebaseDoc,
  mapAttendClients,
  mapClientsFromMap,
};

function mapFirebaseDoc<T>(querySnapshot: QuerySnapshot) {
  const data = querySnapshot.docs;

  return data.map((value) => {
    return { ...value.data(), id: value.id } as T;
  });
}

function mapAttendClients(reports: Report[]): Report[] {
  if (!reports) {
    return [];
  }

  const seen = new Set();
  const duplicated = [];

  const mappedAttendClients: Report[] = reports.filter((item) => {
    const duplicate = seen.has(item.clientCpf);
    if (duplicate) {
      duplicated.push(item);
    }
    seen.add(item.clientCpf);
    return !duplicate;
  });

  const mapObj = new Map();

  duplicated.forEach((v) => {
    const prevValue = mapObj.get(v.clientCpf);
    if (!prevValue || prevValue.time < v.time) {
      mapObj.set(v.clientCpf, v);
    }
  });

  return mappedAttendClients;
}
function mapClientsFromMap(clients) {
  const data = [];

  if (!clients) {
    return data;
  }

  for (const client of clients) {
    data.push({ ...client, bairro: client[`bairro${client.validEnd}`] });
  }

  return data;
}
