import { useEffect } from "react";

import { useStore } from "zustand";
import { Timestamp } from "firebase/firestore";
import attendclientsStore from "@stores/attendClients";
import { GetCardsAttendClientsUseCase } from "@dataUseCases/index";
import { Filter } from "@models/Filters";
import { datetimeHelper } from "@helpers/datetime";
import { FirebaseClientRepository } from "@dataRepositories/index";
import mapClientsStore from "@stores/mapClients";

// const date = new Date();

// const numberContracts = 0;

type Props = {
  headerFilters: Filter[];
};

const dateNow = new Date();
const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
const defaultStartDate = Timestamp.fromDate(firstDay);
const defaultEndDate = Timestamp.fromDate(dateNow);

const getCardsAttendClientsUseCase = new GetCardsAttendClientsUseCase(
  new FirebaseClientRepository(attendclientsStore.getState(), mapClientsStore.getState())
);

export default function useCardsAttendClients({ headerFilters }: Props) {
  // const { user, isAdmin, isManager } = useContext(AuthContext);

  // const { users } = useContext(UsersContext);
  const { dashboardCard } = useStore(attendclientsStore);

  const getCardsAttendClientsAdmin = async (startDate: Timestamp, endDate: Timestamp) => {
    getCardsAttendClientsUseCase.execute({ startDate, endDate });
  };

  // const getAttendClientsManager = async (operatorsArray) => {
  //   if (operatorsArray.length === 0) {
  //     setAttendClients([]);
  //     setReports([]);
  //     return;
  //   }

  //   const operatorsUid = arrayHelper.reduceToSimple(operatorsArray, "uid");

  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("operatorUid", "in", operatorsUid),
  //     orderBy("time", "desc")
  //   );
  //   onSnapshot(
  //     consulta,
  //     (querySnapshot) => {
  //       const mappedDocs = mappers.mapFirebaseDoc(querySnapshot);

  //       const mappedAttendClients = mappers.mapAttendClients(mappedDocs);

  //       setAttendClients(arrayHelper.ordenateArrayDesc(mappedAttendClients, "time"));
  //       setReports(mappedDocs);
  //     },
  //     (error) => {
  //       console.log("getAttendClientsManager error: ", error);
  //     }
  //   );
  // };

  // const getReportsUser = async (user) => {
  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("operatorUid", "==", user.uid),
  //     where("result", "==", "Retornar")
  //   );
  //   await onSnapshot(
  //     consulta,
  //     (querySnapshot) => {
  //       const mappedDocs = mappers.mapFirebaseDoc(querySnapshot);

  //       setReportsUser(arrayHelper.ordenateArrayDesc(mappedDocs, "time"));
  //     },
  //     (error) => {
  //       console.log("getReportsUser error: ", error);
  //     }
  //   );
  // };

  // //Notificações web de novos contratos
  // const checkNewContracts = async () => {
  //   const timeToCompare = new Date(`${date.getMonth() + 1}-1-${date.getFullYear()}`);
  //   const consulta = query(
  //     collection(db, "reports"),
  //     where("result", "in", ["Contrato fechado", "Contrato fechado por indicação"])
  //   );
  //   await onSnapshot(consulta, (querySnapshot) => {
  //     const database = [];

  //     const data = querySnapshot.docs;

  //     for (const i in data) {
  //       database.push(data[i].data());
  //     }

  //     if (numberContracts != 0 && database.length > numberContracts) {
  //       toast("Novo contrato fechado", alertSuccessStyle);
  //     }

  //     numberContracts = database.length;
  //   });
  // };

  // useEffect(() => {
  //   if (isAdmin) {
  //     if (!attendClients) {
  //       getAttendClientsAdmin();
  //       checkNewContracts();
  //     }
  //   }

  //   if (isManager) {
  //     if (users && !attendClients) {
  //       getAttendClientsManager(users.operators);
  //       checkNewContracts();
  //     }
  //   }

  //   if (!isAdmin && !isManager) {
  //     if (!reportsUser && user) {
  //       getReportsUser(user);
  //     }
  //   }
  // }, [users]);

  useEffect(() => {
    if (dashboardCard.length === 0) {
      getCardsAttendClientsAdmin(defaultStartDate, defaultEndDate);
    }
  }, []);

  useEffect(() => {
    const headerDateFilter = headerFilters.find((x) => x.type === "date");

    if (headerDateFilter && headerDateFilter?.dateValue) {
      const startDate = datetimeHelper.getTimestampFromFilter(
        headerDateFilter.dateValue.selectedStart
      );
      const endDate = datetimeHelper.getTimestampFromFilter(headerDateFilter.dateValue.selectedEnd);

      getCardsAttendClientsAdmin(startDate, endDate);
    } else {
      getCardsAttendClientsAdmin(defaultStartDate, defaultEndDate);
    }
  }, [headerFilters]);

  return {
    cardsAttendClients: dashboardCard,
  };
}
