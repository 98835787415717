import React from "react";

import { Container, Text } from "./styles";

type Props = {
  backgroundColor: string;
  size: number;
  text?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
};

const PointIcon = ({ text, textColor, backgroundColor, size, fontSize, fontWeight }: Props) => {
  return (
    <Container backgroundColor={backgroundColor} size={size}>
      <Text textColor={textColor} fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Text>
    </Container>
  );
};

export default PointIcon;
