import DatabaseClientRepository from "@domainRepositories/DatabaseClientRepository";
import UseCase from "@domainUseCases/UseCase";

type Input = void;

type Output = void;

export class GetMapClientsUseCase implements UseCase<Input, Output> {
  constructor(private readonly databaseClientRepository: DatabaseClientRepository) {}
  execute() {
    return this.databaseClientRepository.getMapClients();
  }
}
