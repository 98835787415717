import React, { useEffect, useState } from "react";

import * as S from "./styles";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryTooltipProps,
  VictoryVoronoiContainer,
} from "victory";
import { HiOutlineChartBar } from "react-icons/hi";
import PointIcon from "@components/Icons/PointIcon/PointIcon";
import Divider from "@components/Divider/Divider";
import useChart from "@hooks/useChart";
import { ChartDataResponse } from "@models/ChartDataResponse";
import { monthNames } from "@constants/monthNames";
import { ChartsFilters } from "@pages/Dashboard/DashboardPage";

const lines = [
  { label: "Linha vermelha", value: "line1", color: "var(--red)" },
  { label: "Linha azul", value: "line2", color: "var(--blue4)" },
  { label: "Linha verde", value: "line3", color: "var(--aqua)" },
];

export const yAxisStyle = {
  axis: { stroke: "transparent" },
  grid: { stroke: "transparent" },
  tickLabels: {
    fontSize: 12,
    padding: 5,
    fill: "#554040",
    opacity: 0.5,
  },
};

const chartWidth = window.innerWidth / 2;

const defaultChartsData = {
  line1: [],
  line2: [],
  line3: [],
};

type Props = {
  filters: ChartsFilters;
  selectedLine: string;
  setSelectedLine: (newLine: string) => void;
};

export default function Charts({ filters, selectedLine, setSelectedLine }: Props) {
  const { getChartClients } = useChart();

  const [chartsData, setChartsData] = useState(defaultChartsData);

  useEffect(() => {
    (async () => {
      await filterCharts(filters);
    })();
  }, [filters]);

  const filterCharts = async (filters: Props["filters"]) => {
    let chartData = defaultChartsData;

    for (const line of lines) {
      if (filters[line.value]) {
        if (line.value === "line1") {
          const array = await getChartClients(filters[line.value]);
          chartData = {
            ...chartData,
            [line.value]: filterNewChart(array),
          };
        } else {
          if (filters[line.value].length > 0) {
            const array = await getChartClients(filters[line.value]);
            chartData = {
              ...chartData,
              [line.value]: filterNewChart(array),
            };
          } else {
            chartData = {
              ...chartData,
              [line.value]: [],
            };
          }
        }
      }
    }

    setChartsData(chartData);
  };

  const filterNewChart = (data: ChartDataResponse[]) => {
    const array = [];

    for (const index in data) {
      const item = data[index];

      let name = monthNames.redux[item.month - 1];

      if (Number(index) === 0) {
        name = `${monthNames.redux[item.month - 1]}/${item.year}`;
      }

      array.push({
        x: name,
        y: item.value,
      });
    }

    return array;
  };

  const VictoryCustomTooltip = (props: VictoryTooltipProps) => {
    const { datum, x, y, activePoints } = props;
    const color = activePoints[0] ? activePoints[0].style.data.stroke : "#C85A5A";

    return (
      <g style={{ pointerEvents: "none" }}>
        <foreignObject
          x={x}
          y={(y || 50) - 50}
          width="20"
          height="20"
          style={{ overflow: "visible" }}
        >
          <S.TooltipArea color={color}>
            <div>
              <p>{datum.y}</p>
            </div>
          </S.TooltipArea>
        </foreignObject>
        <g>
          <circle cx={x} cy={y} r="5" stroke={color} strokeWidth={2} fill="white" />
        </g>
      </g>
    );
  };

  return (
    <S.ChartContainer>
      <S.ChartHeader>
        <div>
          <HiOutlineChartBar color="var(--red)" />
          <h3>Gráficos</h3>
        </div>
      </S.ChartHeader>
      <S.ChartArea>
        <S.SvgArea>
          <svg>
            <defs>
              <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="rgba(208, 75, 75, 0.35)" />
                <stop offset="68.23%" stopColor="rgba(208, 75, 75, 0)" />
              </linearGradient>
            </defs>
          </svg>
        </S.SvgArea>
        <VictoryChart
          width={chartWidth}
          height={250}
          containerComponent={
            <VictoryVoronoiContainer
              labels={() => " "}
              labelComponent={<VictoryTooltip flyoutComponent={<VictoryCustomTooltip />} />}
            />
          }
        >
          {chartsData.line1 && (
            <VictoryArea
              data={chartsData.line1}
              interpolation="cardinal"
              style={{
                data: {
                  fill: "url(#myGradient)",
                  stroke: lines[0].color,
                  strokeWidth: 3,
                },
              }}
              labelComponent={<VictoryTooltip />}
            />
          )}

          {chartsData.line2 && (
            <VictoryArea
              data={chartsData.line2}
              interpolation="cardinal"
              style={{
                data: {
                  fill: "url(#myGradient)",
                  stroke: lines[1].color,
                  strokeWidth: 3,
                },
              }}
              labelComponent={<VictoryTooltip />}
            />
          )}

          {chartsData.line3 && (
            <VictoryArea
              data={chartsData.line3}
              interpolation="cardinal"
              style={{
                data: {
                  fill: "url(#myGradient)",
                  stroke: lines[2].color,
                  strokeWidth: 3,
                },
              }}
              labelComponent={<VictoryTooltip />}
            />
          )}

          <VictoryAxis dependentAxis style={yAxisStyle} />
          <VictoryAxis style={yAxisStyle} />
        </VictoryChart>
      </S.ChartArea>

      <Divider width="100%" />

      <S.ChartLinesContainer>
        {lines.map((line, index) => (
          <S.ChartLineFilter key={index}>
            <S.ChartLineTitle
              color={line.color}
              selected={selectedLine === line.value ? true : false}
              onClick={() => {
                setSelectedLine(line.value);
              }}
            >
              <PointIcon backgroundColor={line.color} size={10} />
              <h1>{line.label}:</h1>
            </S.ChartLineTitle>
            {filters[line.value].length > 0 ? (
              filters[line.value].map((item, index) => {
                if (item?.type === "date") {
                  const selectedStart = item?.values?.selectedStart;
                  const selectedEnd = item?.values?.selectedEnd;

                  if (selectedStart && selectedEnd?.day) {
                    return (
                      <S.ChartLineItem key={index}>
                        <h4>
                          {`${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`}-{" "}
                          {`${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`}
                        </h4>
                      </S.ChartLineItem>
                    );
                  }

                  if (selectedStart) {
                    return (
                      <S.ChartLineItem key={index}>
                        <h4>{`${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`}</h4>
                      </S.ChartLineItem>
                    );
                  }
                } else {
                  return (
                    <S.ChartLineItem key={index}>
                      <h4>
                        {item?.values.length > 1
                          ? `${item?.values[0].label} + ${item?.values.length - 1}`
                          : item?.values[0].label}
                      </h4>
                    </S.ChartLineItem>
                  );
                }
              })
            ) : (
              <>
                {line.value === "line1" && (
                  <S.ChartLineItem>
                    <h4>Todos</h4>
                  </S.ChartLineItem>
                )}
              </>
            )}
          </S.ChartLineFilter>
        ))}
      </S.ChartLinesContainer>
    </S.ChartContainer>
  );
}
