import DatabaseClientRepository from "@domainRepositories/DatabaseClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Timestamp } from "firebase/firestore";

export type GetCardsAttendClientsInput = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export type GetCardsAttendClientsOutput = void;

export class GetCardsAttendClientsUseCase
  implements UseCase<GetCardsAttendClientsInput, GetCardsAttendClientsOutput>
{
  private readonly DatabaseClientRepository: DatabaseClientRepository;

  constructor(DatabaseClientRepository: DatabaseClientRepository) {
    this.DatabaseClientRepository = DatabaseClientRepository;
  }
  execute({ startDate, endDate }: GetCardsAttendClientsInput) {
    return this.DatabaseClientRepository.getDashboardCardsAttendClients(startDate, endDate);
  }
}
