export const speciesColors = [
  {
    specie: "LOAS DEF",
    tagColor: "#808B9F",
    textColor: "#FFFFFF",
  },
  {
    specie: "LOAS IDOSO",
    tagColor: "#9BBEFF",
    textColor: "#FFFFFF",
  },
  {
    specie: "AUX. DOENÇA",
    tagColor: "#DE6161",
    textColor: "#FFFFFF",
  },
  {
    specie: "AUX. BRASIL",
    tagColor: "#ffd41f",
    textColor: "#FFFFFF",
  },
  {
    specie: "PENSÃO POR MORTE",
    tagColor: "#8a2f62",
    textColor: "#FFFFFF",
  },
  {
    specie: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
    tagColor: "#482c21",
    textColor: "#FFFFFF",
  },
  {
    specie: "AUX. ACIDENTE",
    tagColor: "#6ca6a3",
    textColor: "#FFFFFF",
  },
];
