import styled from "styled-components";
import { LoadingProps } from "./Loading";

export const Container = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;
  align-self: center;
  background: ${(props) => (props.background ? props.background : "transparent")};
`;
