import { create } from "zustand";
import { User as FirebaseUser } from "firebase/auth";
import { UserStore } from "@domainStores/UserStore";

const userStore = create<UserStore>((set) => ({
  user: null,
  firebaseUser: {} as FirebaseUser,
  loading: true,
  updateUser: (newUserData) =>
    set((state) => {
      return {
        ...state,
        loading: false,
        user: newUserData,
      };
    }),
  updateFirebaseUser: (firebaseUser) =>
    set((state) => {
      return {
        ...state,
        firebaseUser: firebaseUser,
      };
    }),
}));

export default userStore;
