import MapRepository from "@domainRepositories/MapRepository";
import UseCase from "@domainUseCases/UseCase";
import { Map } from "@models/Map";

type Input = void;

type Output = Promise<Map[]>;

export class GetMapsUseCase implements UseCase<Input, Output> {
  constructor(private readonly mapRepository: MapRepository) {}
  execute() {
    return this.mapRepository.getAll();
  }
}
