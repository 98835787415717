import { useEffect } from "react";
import { useStore } from "zustand";
import attendclientsStore from "@stores/attendClients";
import { GetTableAttendClientsUseCase } from "@dataUseCases/index";
import { Filter } from "@models/Filters";
import { Paging } from "@models/Paging";
import { FirebaseClientRepository } from "@dataRepositories/index";
import mapClientsStore from "@stores/mapClients";

type Props = {
  filters: Filter[];
  paging: Paging;
};

const getTableAttendClients = new GetTableAttendClientsUseCase(
  new FirebaseClientRepository(attendclientsStore.getState(), mapClientsStore.getState())
);

export default function useTableAttendClients({ filters, paging }: Props) {
  const { dashboardTable, dashboardTableTotal } = useStore(attendclientsStore);

  const getTableAttendClientsAdmin = async () => {
    getTableAttendClients.execute({ paging });
  };

  useEffect(() => {
    if (dashboardTable.length === 0) {
      getTableAttendClientsAdmin();
    }
  }, []);

  useEffect(() => {
    if (filters.length === 0) {
      getTableAttendClientsAdmin();
    }
  }, [paging]);

  return {
    tableAttendClients: dashboardTable.slice(paging.offset, paging.offset + 25),
    tableAttendClientsTotal: dashboardTableTotal,
  };
}
