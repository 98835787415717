import { Client } from "@models/Client";
import { create } from "zustand";

type ReturnType = {
  completed: string;
  expired: string;
  pending: string;
  premium: string;
};

export type Returns = {
  completed: Client[];
  expired: Client[];
  pending: Client[];
  premium: Client[];
};

interface ReturnStore {
  returns: Returns;
  updateReturns: (type: keyof ReturnType, newClients: Client[]) => void;
  updateReturnsRaw: (
    newReturns: Omit<ReturnStore["returns"], "updateReturns" | "updateReturnsRaw">
  ) => void;
}

const returnStore = create<ReturnStore>((set) => ({
  returns: {
    completed: [],
    expired: [],
    pending: [],
    premium: [],
  },
  updateReturns: (type, newClients) =>
    set((state) => {
      return {
        ...state,
        returns: {
          ...state.returns,
          [type]: newClients,
        },
      };
    }),
  updateReturnsRaw: (newReturns) =>
    set((state) => {
      return {
        ...state,
        returns: newReturns,
      };
    }),
}));

export default returnStore;
