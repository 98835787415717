import UserRepository from "@domainRepositories/UserRepository";
import UseCase from "@domainUseCases/UseCase";
import { User } from "@models/User";

export type GetUserInput = {
  id: string;
};

export type GetUserOutput = Promise<User>;

export class GetUserUseCase implements UseCase<GetUserInput, GetUserOutput> {
  constructor(private readonly userRepository: UserRepository) {
    this.userRepository = userRepository;
  }
  async execute(input: GetUserInput): Promise<User> {
    return this.userRepository.get(input.id);
  }
}
