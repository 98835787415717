import React, { useState } from "react";

import { Circle, Container, Header, ListContainer, RankingHeader } from "./styles";

//Icons
import { AiOutlineCloseCircle } from "react-icons/ai";
import { RiMedal2Line } from "react-icons/ri";

//Custom components
// import DownloadButton from "../DownloadButton";
import Loading from "@components/Loading/Loading";

//Utils
import Modal from "react-modal";
// import ModalDetailsAdmin from "../ModalDetailsAdmin";
import ModalDashboardItem from "../ModalDashboardItem/ModalDashboardItem";

//MUI
import { Avatar } from "@mui/material";
import NoRowsOverlay from "@components/NoRowsOverlay/NoRowsOverlay";
import { avatarHelper } from "@helpers/avatar";
// import CustomFilterHeader from "../CustomFilterHeader";
import { filtersHelper } from "@helpers/filters";
import { TableProps } from "@components/Table/Table";
import { Client } from "@models/Client";

type ModalDashboardCardProps = {
  data: {
    array: Client[];
    title: string;
    type: "contratos" | "clientes" | null;
    columns: number;
    filterTypes: unknown;
    tableColumns: TableProps["tableColumns"];
    visible: boolean;
  };
  onClose: () => void;
};

function ModalDashboardCard({ data, onClose }: ModalDashboardCardProps) {
  const [currentClient, setCurrentClient] = useState<Client>(null);

  //filters
  const [filters, setFilters] = useState([]);

  //Modal
  const [modalFullData, setModalFullData] = useState(false);

  const filterList = (): Client[] => {
    return filtersHelper.filterDataTable<Client>(data.array, filters);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  if (!data) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (data.array.length === 0) {
    return (
      <Container>
        <NoRowsOverlay />
      </Container>
    );
  }

  const tableData: Client[] = filters.length > 0 ? filterList() : data.array;

  return (
    <Container>
      <Header>
        <h3>{data.title}</h3>

        <div>
          {/* {data.filterTypes && (
            <CustomFilterHeader
              dateField="time"
              filters={filters}
              filterTypes={data.filterTypes}
              tableRowsBackup={data.array}
              onChangeFilters={(newFiltersArray) => {
                setFilters(newFiltersArray);
              }}
              onClearFilters={() => clearFilters()}
            />
          )} */}
          {/* <DownloadButton tableColumns={data.tableColumns} tableRows={tableData} /> */}
          <AiOutlineCloseCircle
            style={{ marginLeft: 10 }}
            onClick={onClose}
            strokeWidth={0.5}
            cursor="pointer"
            size={22}
          />
        </div>
      </Header>
      {data.type && (
        <RankingHeader>
          <Circle>
            <RiMedal2Line color="white" />
          </Circle>
          {tableData.length > 0 && (
            <Avatar
              alt="Avatar"
              src={avatarHelper.getUserAvatar(tableData[0].operatorId)}
              sx={{
                width: 70,
                height: 70,
                borderRadius: 100,
                borderColor: "white",
                borderWidth: 10,
              }}
            />
          )}

          <h1>{tableData[0].operatorName}</h1>
          <h3>
            {tableData.length} {data.type}
          </h3>
        </RankingHeader>
      )}
      <ListContainer height={data.type ? "55vh" : "75vh"}>
        {tableData &&
          tableData.map((item, index) => (
            <ModalDashboardItem
              key={index}
              data={item}
              showAvatar={!data.type}
              columns={data.columns}
              onViewFullData={() => {
                setCurrentClient(item);
                setModalFullData(true);
              }}
            />
          ))}
      </ListContainer>

      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        {/* <ModalDetailsAdmin
          data={currentClient}
          onClose={() => setModalFullData(false)}
          onStreetView={() => {}}
        /> */}
      </Modal>
    </Container>
  );
}

export default ModalDashboardCard;
